import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SettingsVwComponent } from './settings-vw/settings-vw.component';

import { FormBuilder,Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { Globals } from 'src/app/global/global-variable';
import { Router } from '@angular/router';

import { SettingService } from '../services/setting-service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { lastValueFrom } from 'rxjs';

import { CommonService } from '../library/pcl_lib';

import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';

//pdfMake
var pdfMake = require("pdfmake/build/pdfmake");
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
    form = this.fb.group({
        id          : [''],
        action      : ['add'],
        source      : ['',Validators.required],
        source_link : ['',Validators.required]
    });

    form_upload = this.fb.group({
        type : ["individual"],
        attachment : [null],
        attachment_placeholder  : [null]
    });
    totalFileSize = 0;

    form2 = this.fb.group({
        attachment          : [''],
    })

    field_label = {
        source       : "Source",
        source_link    : "Source Link",
        attachment : "Selected file"
    }

    

    table_list          = new Array();
    show_add_btn        = false;
    show_edit_btn       = false;
    show_sync_btn       = false;

    for_delete                  = {};

    @ViewChild('modal_view_close_button') closebutton;
    @ViewChild('confirmation_close_button') modalclosebutton;
    constructor(
        
        private router          : Router,
        private fb              : FormBuilder,
        private toastr          : ToastrService,
        private cdRef           : ChangeDetectorRef  ,
        private settingService : SettingService,
        private global           : Globals,
        private datePipe : DatePipe,
        public commonService : CommonService,
        private ngxCsvParser: NgxCsvParser

    ){
        
    }//end
    async ngOnInit(){

        //var test = this.generate_sample_pdf();

         //check permission
         let w_access     = false;
         const permission = this.global.login_session["permission"]["settings"]
         for(const k in permission){
             if(permission[k] == 'view'){
                 w_access = true;
             }//end if
 
             if(permission[k] == 'add'){
                 this.show_add_btn = true;
             }//end if

            if(permission[k] == 'edit'){
                this.show_edit_btn = true;
            }//end if
            
            if(permission[k] == 'sync'){
                this.show_sync_btn = true;
            }//end if
             
         }//end for
         if(!w_access){
             window.location.replace('/main/dashboard');
         }//end if
         //end check permission
       
        this.refresh_list(); 
       
        
    }//end
    
    generate_sample_pdf(){
        var fonts = {
            Src_Code: {normal: 'https://cdn.jsdelivr.net/npm/source-code-pro@2.30.2/TTF/SourceCodePro-Regular.ttf'},
            Vazir: {normal: 'https://cdn.jsdelivr.net/gh/rastikerdar/vazir-font@v27.2.2/dist/Vazir-Regular.ttf'},
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            },
            Noto_Sans : {
                italics : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Italic.ttf',
                normal : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Regular.ttf',
                bold : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Bold.ttf'
            },
            Noto_Sans_Arabic : {
                normal : window.location.origin+'/assets/fonts/unicode/Arial Unicode MS.TTF',
                
                //normal : window.location.origin+'/assets/fonts/open-sans/OpenSans-Regular.ttf',
                //bold : window.location.origin+'/assets/fonts/open-sans/OpenSans-Bold.ttf'
            }
           
        };
        var  content = {
            stack:[
              {
                text: 'My title',
                fontSize: 24,
                bold: true,
                marginBottom: 5,
                style: ['html-h1']
              },
              {
                text: [
                  {
                    text: 'This is a sentence with a '
                  },
                  {
                    text: 'bold word',
                    bold: true,
                    style: ['html-strong']
                  },
                  {
                    text: ', '
                  },
                  {
                    text: 'one in italic',
                    italics: true,
                    style: ['html-em']
                  },
                  {
                    text: ', and '
                  },
                  {
                    text: 'one with underline',
                    decoration: 'underline',
                    style: ['html-u']
                  },
                  {
                    text: '. And finally '
                  },
                  {
                    text: 'a link',
                    color: 'blue',
                    decoration: 'underline',
                    link: 'https://www.somewhere.com',
                    style: ['html-a']
                  },
                  {
                    text: '.'
                  }
                ],
                margin: [0, 5, 0, 10],
                style: ['html-p']
              }
            ],
            style: ['html-div']
          }
        var template = {
            content : content,
            pageSize: 'A4',
            pageMargins: [ 62,42,62,90 ],
            styles:{
                'with-color-white':{
                    color:'#ffffff' // it will add a yellow background to all <STRONG> elements
                },
                'with-orange':{
                    color:'#ffffff' // it will add a yellow background to all <STRONG> elements
                },
                tableExample: {
                    margin: [0, 0, 0, 0]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 14,
                    color: '#000'
                },
                tableFooter: {
                    fontSize: 8,
                    color: '#ffffff'
                },
                tableFooterUnawa: {
                    bold: true,
                    fontSize: 10,
                    color: '#ffffff'
                },
                tableInfo: {
                    fontSize: 9,
                    color: '#000'
                },
                tableInfoContact: {
                    fontSize: 7,
                    bold: true,
                    color: '#000'
                },
                tableLabel: {
                    fontSize: 12,
                    color: '#000',
                    bold : true
                },
                tableLabelInfo: {
                    fontSize: 12,
                    color: '#C2C2C1'
                },
                tableLabelInfoFooter: {
                    fontSize: 9,
                    color: '#C2C2C1'
                },
                fontRegular : {
                    fontSize: 12,
                },
                defaultStyle: {
                    color : '#F17821'
                }
            }
        }
        var test = pdfMake.createPdf(template,null,fonts);
        test.getBase64(function(buffer) {
            console.log('test',buffer);
        });
        
        return test;
    }

    
 

    refresh_list(){
        
        this.global.loading = true;
        
        const query_param = {
            "record_type" : environment.setting,
            "pageSize" : 1000,
            "orderByFieldArr" : [
                "id",
                "date_created"
            ],
            "sortOrderArr" : [
                "asc",
                "desc"
            ]
        }//end

        //return this.settingService.filterList(query_param);
        this.settingService.filterList(query_param).subscribe(res => {
            if(res["success"]){
                this.table_list = res["data"]["query_data"];

                for(const k in this.table_list){
                    const m_data = this.table_list[k];
                    if(m_data["last_update_date"] !== undefined){
                        const _date = new Date(new Date(m_data["last_update_date"]["_seconds"] * 1000 + m_data["last_update_date"]["_nanoseconds"]/1000000));
                        var final_date = this.datePipe.transform(_date, 'MMM dd, yyyy hh:mm:ss a');
                        m_data['last_update_date_parsed'] = final_date;
                    }else{
                        m_data['last_update_date_parsed'] = '';
                    }//end if
                    
                    this.table_list[k] = m_data;


                }
            }else{
                console.log(res);
                this.toastr.error(res["data"]["message"],"System Message");
            }//end if
            
            this.global.loading = false;
        });

        
    }//end

    save_data(){
        this.global.loading = true;
        const val = this.form.value;

        try {

            //check permission
            let compare_val  = "";
            if(val.action == 'add'){
                compare_val = 'add';
            }else if(val.action == 'update'){
                compare_val = 'edit';
            }//end if

            let w_access     = false;
            const permission = this.global.login_session["permission"]["settings"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission


            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if
                }//end for
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            //save to firebase
            const request_param = {
                "records" : [{
                    "record_type" : environment.setting,
                    "date_created" : new Date(),
                    ...val
                }]
            }

            this.settingService.save_data(request_param).subscribe(res => {
                if(res["success"]){
                    this.toastr.success("Operation Successfully Completed!","System Message");
                }else{
                    this.toastr.error(res["data"]["message"],"System Message");
                }//end if
                this.global.loading = false;

                
                 //window.location.reload();
                if(val.action == "add"){
                    this.form.reset();
                    this.form.controls['action'].setValue('add');
                }//end if

                 //this.form.reset();
                //this.form.controls['action'].setValue('add');

                this.closebutton.nativeElement.click();
                
            });
            //end save to firebase



        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
        
    }//end create_new

    sync_data(param){

        try {
            //check permission
            let compare_val  = "sync";

            let w_access     = false;
            const permission = this.global.login_session["permission"]["settings"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission

            this.global.loading = true;
            console.log(this.global.login_session["username"])
            const request_param = {
                "data_url" : param.source_link,
                "source_id" : param.id,
                "source" : param.source,
                "email" : this.global.login_session["username"]
            }
            
            this.settingService.process_data(request_param).subscribe(res => {
                if(res["success"]){
                    if(res["data"]["total_created"] !== undefined && res["data"]["total_created"] > 0){
                        
                        this.toastr.success("Data Synced Total Created : "+res["data"]["total_created"],"System Message");
                    }else{
                        this.toastr.info("No data synced!","System Message");
                    }//end if
                }else{
                    this.toastr.error("Error : "+ JSON.stringify(res["data"]),"System Message");
                }//end if

                
            });

            this.global.loading = false;
            this.toastr.info("Data syncing started. An email will be sent to you once sync is finished!","System Message");
        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
        
    }//end if

    edit_data(param){
        this.form.controls['action'].setValue('update');
        for(const k in this.form.controls){
            if(k == "action"){
                continue;
            }
            this.form.controls[k].setValue(param[k]);
        }//end for

       
    }//end

    close(){
        this.form.reset();
        this.form.controls['action'].setValue('add');
        this.for_delete = {};
        this.refresh_list();
    }


    

    upload(event : any){
        //this.totalFileSize = 0;
        if (event.target instanceof HTMLInputElement && event.target.type === "file") {
            const files = event.target.files;
            const allowed_ext = [
                '.docx', 
                '.doc', 
                '.xlsx', 
                '.xls', 
                '.csv', 
                '.pdf', 
                '.png', 
                '.jpg', 
                '.jpeg', 
                '.svg'
            ];
            
          

            let file_arr = new Array();
            
            for(const k in files){
                if (isNaN(parseInt(k))) {
                    continue;
                }//end if
                
                const file          = files[k];
                const file_ext      = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
                
                const KBSize        = (parseFloat(file.size) / 1024) / 1024;
                //this.totalFileSize  += KBSize;

                if(!allowed_ext.includes(file_ext)){
                    this.form.controls['attachment'].setErrors({'invalid_file': true}); 
                }//end if

                
              

                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                  file_arr.push({
                        path : <any>reader.result
                    });

                    let csvToRowArray = reader.result.toString().split("\n");
                    let userArray = new Array();
                    for (let index = 0; index < csvToRowArray.length - 1; index++) {
                        let row = csvToRowArray[index].split(",");

                        if(index == 0){
                            for(const k in row){
                                userArray.push(row[k]);
                            }//end for
                        }//end if
                        

                        //userArray.push(new User( parseInt( row[0], 10), row[1], row[2].trim()));
                    }
                    console.log(userArray);
                }

            
                
            }//end if
          
            //this.totalFileSize = this.commonService.catchIsNaN(this.totalFileSize,2)

            //this.form.patchValue({
               // attachment_placeholder : file_arr
            //});

           
            //set max file size
            //if(this.totalFileSize > 25){
                //this.form.controls['attachment'].setErrors({'exceed_file_size': true});  
           // }//end if
            //end set max file size

            
        }//end if
       
    }

    set_for_delete(param){
        this.for_delete = param;
    }//end if



    delete_data(param){

        

        try {
            //check permission
            let compare_val  = "delete";
            let w_access     = false;
            const permission = this.global.login_session["permission"]["manage_user"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            //if(confirm('Are you sure you want to delete this user?')){
                this.global.loading = true;
                const request_param = {
                    "records" : [{
                        "record_type"   : environment.setting,
                        "action" : "delete",
                        "id"  : param["id"],
                    }]
                }
    
                this.settingService.save_data(request_param).subscribe(response => {
                    console.log(response);
                    if(response["success"]){
                        this.toastr.success("Source is successfully deleted!","System Message");
                    }else{
                        this.toastr.error(response["data"]["message"],"System Message");
                       
                    }//end if

                    //close modal automatically
                    this.modalclosebutton.nativeElement.click();
                    this.global.loading = false;

                    
    
                    this.refresh_list();

                    
                })
            //}//end if

            
            //end check permission
        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
        
        
    }



    upload_csv(event : any){
        this.totalFileSize = 0;
        if (event.target instanceof HTMLInputElement && event.target.type === "file") {
            const files = event.target.files;
            const allowed_ext = [
                '.csv'
            ];
            
          

            let file_arr = new Array();
            
            for(const k in files){
                if (isNaN(parseInt(k))) {
                    continue;
                }//end if
                
                const file          = files[k];
                const file_ext      = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
              
                const KBSize        = (parseFloat(file.size) / 1024) / 1024;
                this.totalFileSize  += KBSize;

                if(!allowed_ext.includes(file_ext)){
                    this.form_upload.controls['attachment'].setErrors({'invalid_file': true}); 
                }//end if

                
                file_arr.push({
                    file : file
                });

                /*var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    
                  file_arr.push({
                        path : reader.result
                    });
                }*/
                
            }//end if
          
            //this.totalFileSize = this.commonService.catchIsNaN(this.totalFileSize,2)

            this.form_upload.patchValue({
                attachment_placeholder : file_arr
            });

           
            //set max file size
            if(this.totalFileSize > 25){
                this.form_upload.controls['attachment'].setErrors({'exceed_file_size': true});  
            }//end if
            //end set max file size

            
        }//end if
       
    }
    async upload_file_entity(){
        this.global.loading = true;
        const val = this.form_upload.value;
        try {
            //validate
            let error_html_arr = new Array();
            if(!this.form_upload.valid){
                for(var k in this.form_upload.controls){
                    var field = this.form_upload.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['exceed_file_size'] !== undefined && field.errors?.['exceed_file_size']){
                        error_html_arr.push(this.field_label[k]+' max size exceeded! <br/>');
                    }//end if

                    if(field.errors?.['invalid_file'] !== undefined && field.errors?.['invalid_file']){
                        error_html_arr.push(this.field_label[k]+' contains invalid file type! <br/>');
                    }//end if
                }//end for

                


            }//end if
            //end validate

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if


            //sanitize
            const attachments = val.attachment_placeholder;
            for(const k in val){
                if(k.includes('_placeholder')){
                    delete val[k];
                }//end if
            }//end for
            //end sanitize

            if(val.attachment !== null){
                var csv_data = {};
                for(var s in attachments){
                    const m_data = attachments[s];
                    const attachment = m_data.file;

                
                    

                    this.ngxCsvParser.parse(attachment, { header: true, delimiter: ',', encoding: 'utf8' })
                        .pipe().subscribe({
                            next: (result): void => {
                                var error_counter = 0;
                                //console.log(result);
                                for(var s in result){
                                    var row = result[s];

                                   
                                    for(var field in row){
                                        
                                        switch(field) {
                                          

                                            case 'ENTITY_ADDRESS' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;

                                            case 'ENTITY_ALIAS' :
                                                parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                                
                                                
                                            break;

                                            case 'LAST_DAY_UPDATED' :
                                                parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;
                                        }//end
                                    }//end if

                                   
                                    
                                    result[s] = {
                                        'record_type' : environment.entity,
                                        'action' : 'add',
                                        ...row
                                    };
                                }
                               
                                csv_data = result;
                                
                                const request_param = {
                                    "records" : csv_data
                                };

                                console.log('request_param',request_param);

                                this.settingService.save_data(request_param).subscribe(response => {
                                    console.log(response);
                                    if(response["success"]){
                                        this.toastr.success("Uploaded successfully!","System Message");
                                    }else{
                                        this.toastr.error(response["data"]["message"],"System Message");
                                       
                                    }//end if
                
                                    //close modal automatically
                                    this.global.loading = false;
                                })


                                //this.csvRecords = result;
                            },
                            error: (error: NgxCSVParserError): void => {
                                throw error;
                            }
                        });
                    
                    

                    

                    
                }//end for

                

                
            }//end if


        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
    }
    upload_file(){
        var val = this.form_upload.value;
        if(val.type == "individual"){
            this.upload_file_individual();
        }else{
            this.upload_file_entity();
        }//end if
    }
    async upload_file_individual(){
        this.global.loading = true;
        const val = this.form_upload.value;
        try {
            


            //validate
            let error_html_arr = new Array();
            if(!this.form_upload.valid){
                for(var k in this.form_upload.controls){
                    var field = this.form_upload.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['exceed_file_size'] !== undefined && field.errors?.['exceed_file_size']){
                        error_html_arr.push(this.field_label[k]+' max size exceeded! <br/>');
                    }//end if

                    if(field.errors?.['invalid_file'] !== undefined && field.errors?.['invalid_file']){
                        error_html_arr.push(this.field_label[k]+' contains invalid file type! <br/>');
                    }//end if
                }//end for

                


            }//end if
            //end validate

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if


            //sanitize
            const attachments = val.attachment_placeholder;
            for(const k in val){
                if(k.includes('_placeholder')){
                    delete val[k];
                }//end if
            }//end for
            //end sanitize

            if(val.attachment !== null){
                var csv_data = {};
                for(var s in attachments){
                    const m_data = attachments[s];
                    const attachment = m_data.file;

                
                    

                    this.ngxCsvParser.parse(attachment, { header: true, delimiter: ',', encoding: 'utf8' })
                        .pipe().subscribe({
                            next: (result): void => {
                                var error_counter = 0;
                                //console.log(result);
                                for(var s in result){
                                    var row = result[s];

                                   
                                    for(var field in row){
                                        field = field.toLocaleUpperCase();
                                        
                                        switch(field) {
                                            case 'DESIGNATION' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;

                                            case 'INDIVIDUAL_ADDRESS' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;

                                            case 'INDIVIDUAL_ALIAS' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                                
                                                
                                            break;

                                            case 'INDIVIDUAL_DOCUMENT' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;


                                            case 'INDIVIDUAL_PLACE_OF_BIRTH' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;


                                            case 'LAST_DAY_UPDATED' :
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;


                                            case 'NATIONALITY' :
                                                
                                                var parsed_field = new Array();
                                                if(row[field].trim() !== ""){
                                                    parsed_field = JSON.parse(row[field]);
                                                }//end if
                                                
                                                if(Array.isArray(parsed_field)){
                                                    row[field] = parsed_field;
                                                }else{
                                                    const errObj = {
                                                        name : "FORMAT_INVALID",
                                                        message : field+ ' format must be array',
                                                    }
                                                    throw errObj;
                                                }//end if
                                            break;
                                        }//end
                                    }//end if

                                   
                                    
                                    result[s] = {
                                        'record_type' : environment.individual,
                                        'action' : 'add',
                                        ...row
                                    };
                                }
                               
                                csv_data = result;
                                
                                const request_param = {
                                    "records" : csv_data
                                };

                                console.log('request_param',request_param);

                                this.global.loading = false;

                                this.settingService.process_csv_file_upload(request_param).subscribe(response => {
                                    console.log(response);
                                    if(response["success"]){
                                        this.toastr.success("Uploaded successfully!","System Message");
                                    }else{
                                        this.toastr.error(response["data"]["message"],"System Message");
                                       
                                    }//end if
                
                                    //close modal automatically
                                    this.global.loading = false;
                                })
                                
                                


                                //this.csvRecords = result;
                            },
                            error: (error: NgxCSVParserError): void => {
                                throw error;
                            }
                        });
                    
                    

                    //const reader = new FileReader();
                    //reader.readAsText(attachment);
                    
                    /*reader.onload = () => {

                        
                        
                        //var rs = csvJSON(reader.result.toString());
                        
                        console.log(rs)
                        let csvToRowArray = reader.result.toString().split(/\r\n|\n/);
                        var row_header = new Array();
                        var row_lines = new Array();
                        for (let index = 0; index < csvToRowArray.length - 1; index++) {
                            //csvToRowArray[index] = csvToRowArray[index].replace(/,/g,'""')
                            let row = csvToRowArray[index].split(",");

                        
                            if(index == 0){
                                for(const k in row){
                                    row_header.push(row[k]);
                                }//end for
                            }else{
                                for(const k in row){
                                    row_lines.push(row[k]);
                                }//end for

                                csv_data.rows.push(row_lines);
                            }//end if
                            

                            //userArray.push(new User( parseInt( row[0], 10), row[1], row[2].trim()));
                        }//end for
                        csv_data.header.push(row_header);
                        
                    }//end
                    */

                    
                }//end for

                

                
            }//end if


        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
    }
}


function csvJSON(text, quoteChar = '"', delimiter = ',') {
    var rows=text.split("\n");
    var headers=rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');
  
    const match = line => [...line.matchAll(regex)]
      .map(m => m[2]) 
      .slice(0, -1); 
  
    var lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);
    
    return lines.map(line => {
      return match(line).reduce((acc, cur, i) => {
        // replace blank matches with `null`
        const val = cur.length <= 0 ? null : Number(cur) || cur;
        const key = heads[i] ?? `{i}`;
        return { ...acc, [key]: val };
      }, {});
    });
  }