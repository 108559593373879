import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BulkSearchService } from '../services/bulk-search-service';
import { Globals } from '../global/global-variable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonService } from '../library/pcl_lib';
import { ManifestService } from '../services/manifest-service';
import { SearchService } from '../services/search-service';
import { lastValueFrom, shareReplay } from 'rxjs';
@Component({
  selector: 'app-bulk-search-vw',
  templateUrl: './bulk-search-vw.component.html',
  styleUrls: ['./bulk-search-vw.component.scss']
})
export class BulkSearchVwComponent {
	snapshot_param = {};
	show_no_data = false;
	constructor(
		public commonService : CommonService,
		private fb : FormBuilder,
		public global : Globals,
		private datePipe : DatePipe,
		private bulkSearchService : BulkSearchService,
		private toastr : ToastrService,
		private _activatedRouter : ActivatedRoute,
		private manifestService : ManifestService,
		private searchService : SearchService
	){
        this.snapshot_param = this._activatedRouter.snapshot.params;
    }

	table_list = new Array();

	filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [10],
    });

	//for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];

    show_total_search_result    = 0;
    total_search_result         = 0;
    total_result                = 0;

	ngOnInit(){
		this.refresh_list();
		//alert(this.snapshot_param["id"]);
	}

	refresh_list(){
		this.global.loading = true;
		var val = this.filter_form.value;
		let filters = {};
		filters["filterFieldArr"]  = new Array();
		filters["filterOperatorArr"] = new Array();
		filters["filterValueArr"] = new Array();
		filters["orderByFieldArr"] = new Array();
        filters["sortOrderArr"] = new Array();
		


		filters["filterFieldArr"].push("bulk_search_id");
		filters["filterOperatorArr"].push("==");
		filters["filterValueArr"].push(this.snapshot_param["id"]);

		

		if(val.filter_search !== ""){
			filters["filterFieldArr"].push("search_searched_value");
			filters["filterOperatorArr"].push("array-contains");
			filters["filterValueArr"].push(val.filter_search.toLowerCase());
		}//end if

		filters["orderByFieldArr"].push("query_result");
		filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
		filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("desc");

		let record_type = "";
		if(this.snapshot_param["type"] == 'sanction'){
			filters["filterFieldArr"].push("type");
			filters["filterOperatorArr"].push("==");
			filters["filterValueArr"].push("search_count");
			record_type = environment.dashboard_counter;
			
		}else if(this.snapshot_param["type"] == 'pep'){
			record_type = environment.dashboard_counter_wiki;
			filters["filterFieldArr"].push("type");
			filters["filterOperatorArr"].push("==");
			filters["filterValueArr"].push("hit_count");
		}else if(this.snapshot_param["type"] == 'adverse'){
			record_type = environment.dashboard_counter_adverse;
			filters["filterFieldArr"].push("type");
			filters["filterOperatorArr"].push("==");
			filters["filterValueArr"].push("hit_count");
		}

		const data_param = {
			"record_type" : record_type,
			"pageNumber" : this.pageNumber,
			"lastVisibleField" : this.lastVisibleField,
			"lastVisibleVal" : this.lastVisibleVal,
			"previous" : this.previous,
			"pageSize" : val.page_size_placeholder,
			...filters
		}//end

		
                

		console.log('data_param',data_param);

		this.bulkSearchService.filterList(data_param).subscribe(res => {
			console.log('res',res);
			if(res["success"]){
				//console.log(res["data"]["query_data"]);
				this.table_list                 = res["data"]["query_data"];
				//convert date
				for(var k in this.table_list){
					const es = this.table_list[k];
					if(es["date_created"] !== undefined){
						var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
						this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
					}//end if

					
				}//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
				//convert date

				this.total_search_result        = res["data"]["total_result"];
				this.total_result               = res["data"]["total_result"];
				this.show_total_search_result   = res["data"]["total_result"];
				this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
				this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
			}else{
				//console.log(res);
                this.toastr.error(res["data"]["message"],"System Message");
			}
			this.global.loading = false;
		})
	}

	search(){
       
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal          = new Array();
        this.pageNumber                  = 0;
        this.refresh_list()
    }

	prev(){
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
        
        
        
    }

    next(){
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];
           
            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if

            this.refresh_list();
            
            
        }//end if
    }

	search_wikidata_promise(search_type : string,name : string){
        var schema = '';
        if(search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if
        

        var request_param = {
            "action" : "wbsearchentities",
            "search" : name.toLocaleLowerCase().trim(),
            "language" : "en",
            "format" : "json",
            "limit" : 50,
            "continue" : 0,
            "created_by": this.global.login_session["id"],
            "search_type": search_type,
            "save_search_result" : false
        
        }
        console.log('request_param',request_param)
        return this.searchService.search_wikidata(request_param).pipe(
            shareReplay(1)
        );
    }//end

	generate_manifest_adverse(search_id : string,name : string,search_type : string){
        this.global.loading = true;
     
        var url_request = window.location.origin+"/search-result/report-adverse/"+search_type+"/"+search_id;
        var request_param = {
            "search_id" : search_id,
            "file_name" : name+".pdf",
            "url" : url_request,
            "sort" : "date:d"
          
        };

        this.manifestService.generate_manifest_adverse(request_param).subscribe(result => {
        
            if(result["success"]){
                const url = result["data"]["manifest_link"];
                window.open(url,"_blank");
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message")
            }//end if
            this.global.loading = false;
        });

        window.setTimeout(function(){
            var message = 'Your report is currently being generated. You may wait for it to load in this page. It will also be available in the "Reports" page within 5 minutes.';
            alert(message);
        },2000);
    }

	async generate_manifest_wikidata(search_id : string,name : string,search_type : string){
        this.global.loading = true;
		console.log('search_id',search_id);
		console.log('name',name);
		console.log('search_type',search_type);

        var wikidata_id = new Array();
		try{
			//------------------------------get wikidata------------------------------
			let r_param = new Array();
			r_param.push(lastValueFrom(this.search_wikidata_promise(search_type,name)));
			await Promise.allSettled(r_param).then((r) => {
				r.forEach((res, index) => {
					if(res.status === 'fulfilled') {
						const result = (res.value);
						if(result['success']){
							const data_search = result["data"]["search"];
							for(const k in data_search){
								const map_data = data_search[k];
								wikidata_id.push(map_data["id"])
							}//end for
						}else{
							var error_message = result["data"]["error"];
							this.toastr.error(error_message["message"],"System Message");
						}//end if
					}//end if
				})
			});
			//------------------------------get wikidata------------------------------
	
			

			
			var page = 0;
			var url_request = window.location.origin+"/search-result/report-wiki/"+search_type+"/"+search_id+"/"+(page+1);
			var request_param = {
				"search_id" : search_id,
				"file_name" : name+".pdf",
				"page" : (page+1),
				"url" : url_request,
				"wikidata_id" : wikidata_id
			};
			this.manifestService.generate_manifest_wikidata(request_param).subscribe(result => {
			
				if(result["success"]){
					const url = result["data"]["manifest_link"];
					window.open(url,"_blank");
				}else{
					console.log(result);
					this.toastr.error("Failed to generate report!","System Message")
				}//end if
				this.global.loading = false;
			});

			window.setTimeout(function(){
				var message = 'Your report is currently being generated. You may wait for it to load in this page. It will also be available in the "Reports" page within 5 minutes.';
				alert(message);
			},2000);
			
		}catch(e : any) {
			console.log(JSON.stringify(e));
			this.toastr.error("Failed to generate report!","System Message")
		}//end catch

        
    }//end
}
