<p>multifactor works!</p>
<div id="recaptcha-container-id"></div>
<form [formGroup]="form">
    <select formControlName="selectlist">
        <option *ngFor="let data of select_data_list" value="{{data.id}}">{{data.name}}</option>
    </select>
    <input type="text" formControlName="verificationCode">
    <button (click)="test()">TEST</button>

    <button (click)="test2()">2FA Registered</button>

    <button (click)="submit()">Submit</button>
    <button (click)="submit2()">Submit Registered</button>
</form>


