export const environment = {
  production              : true,
  firebase: {
    projectId: 'cstf-v2',
    appId: '1:276946023370:web:d316f79746bfb57489d533',
    databaseURL: 'https://cstf-v2-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'cstf-v2.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCs1ZSWtmqC9Db_LlUZBaKJwmsL-aRj4QA',
    authDomain: 'cstf-v2.firebaseapp.com',
    messagingSenderId: '276946023370',
    measurementId: 'G-TKP16B0HJ7',
  },
  firebaseConfig : {
    apiKey: "AIzaSyCs1ZSWtmqC9Db_LlUZBaKJwmsL-aRj4QA",
    authDomain: "cstf-v2.firebaseapp.com",
    databaseURL: "https://cstf-v2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "cstf-v2",
    storageBucket: "cstf-v2.appspot.com",
    messagingSenderId: "276946023370",
    appId: "1:276946023370:web:d316f79746bfb57489d533",
    measurementId: "G-TKP16B0HJ7"
  },

   /*getVisitorApplicationUr : "https://asia-east2-cstf-v2.cloudfunctions.net/getVisitorApplicationUr_dev",
    process_data_dev : "https://asia-east2-cstf-v2.cloudfunctions.net/process_data_dev",
    save_data_dev : "https://asia-east2-cstf-v2.cloudfunctions.net/save_data_dev",
    get_data_dev : "https://asia-east2-cstf-v2.cloudfunctions.net/get_data_dev",
    get_dashboard_counter_dev : "https://asia-east2-cstf-v2.cloudfunctions.net/get_dashboard_counter_dev",
  */
    getVisitorApplicationUr : "https://asia-east2-cstf-v2.cloudfunctions.net/getVisitorApplicationUr_dev",
    process_data_staging : "https://process-data-dev-gen2-4kzgwodriq-df.a.run.app",
    save_data_staging : "https://save-data-dev-gen2-4kzgwodriq-df.a.run.app/",
    get_data_staging : "https://get-data-dev-gen2-4kzgwodriq-df.a.run.app",
    get_dashboard_counter_staging : "https://get-dashboard-counter-dev-gen2-4kzgwodriq-df.a.run.app",
    send_manifest_staging : "https://send-manifest-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_pdf : "https://generate-pdf-dev-gen2-4kzgwodriq-df.a.run.app",
    process_bulk : "https://process-bulk-dev-gen2-4kzgwodriq-df.a.run.app",
    
    sign_in : "https://signin-user-dev-gen2-4kzgwodriq-df.a.run.app",
    create_user : "https://create-user-dev-gen2-4kzgwodriq-df.a.run.app",
    get_custom_token : "https://generate-custom-token-dev-gen2-4kzgwodriq-df.a.run.app",
    send_user_invite : "https://send-invite-dev-gen2-4kzgwodriq-df.a.run.app",
    validate_user_invite : "https://validate-invite-dev-gen2-4kzgwodriq-df.a.run.app",
    web_api_key : "AIzaSyCs1ZSWtmqC9Db_LlUZBaKJwmsL-aRj4QA",
    google_custom_search : "https://google-custom-search-dev-gen2-4kzgwodriq-df.a.run.app",
    google_custom_search_key : "AIzaSyCRJHmp7cu2ODWHOwvyZgZoYzxa3QCju9s",
    open_api_pep : "https://open-api-dev-gen2-4kzgwodriq-df.a.run.app",
    view_api_pep : "https://view-open-api-dev-gen2-4kzgwodriq-df.a.run.app",
    open_api_key : "ApiKey 3842949c9fdb6575f5406cb7fb80a9e3", //actual api
    //open_api_key : "ApiKey 6bf34afe53cb9de932275fc9b4ffcfae",
    search_wikidata : "https://search-wikidata-dev-gen2-4kzgwodriq-df.a.run.app",
    view_wikidata : "https://view-wikidata-dev-gen2-4kzgwodriq-df.a.run.app",
    enable_elastic : true,
    generate_pdf_wikidata : "https://generate-pdf-wikidata-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_pdf_wikidata_single : "https://generate-pdf-wikidata-single-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_pdf_adverse : "https://generate-pdf-adverse-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_pdf_adverse_single : "https://generate-pdf-adverse-single-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_pdf_single : "https://generate-pdf-single-dev-gen2-4kzgwodriq-df.a.run.app",
    generate_otp : "https://generate-otp-dev-gen2-4kzgwodriq-df.a.run.app",
    verify_otp : "https://verify-otp-dev-gen2-4kzgwodriq-df.a.run.app",
    create_trial_subscription : "https://create-trial-subscription-dev-gen2-4kzgwodriq-df.a.run.app",


    elastic_url : "https://get-data-elastic-dev-gen2-4kzgwodriq-df.a.run.app",
    elastic_index : "search-ucheck_dev",
    elastic_index_entity : "search-ucheck-entity_dev",
    elastic_api_key : "ApiKey VEZ1Ym9aQUJFaXc4SUxHUEc1Nk46MzdEcTJUcmpULU9JRHo0VGdEaEVrQQ==",
    elastic_master_data_url : "https://get-master-data-elastic-dev-gen2-4kzgwodriq-df.a.run.app",
    elastic_source_list : "source-list_dev",
    elastic_entity_source_list : "entity-source-list_dev",
    elastic_setting : "setting_dev",
    elastic_adverse_parameters : "adverse-parameters_dev",

    maya_process_payment : "https://process-payment-dev-gen2-4kzgwodriq-df.a.run.app",
    maya_process_payment_status : "https://process-payment-status-dev-gen2-4kzgwodriq-df.a.run.app",
    get_subscription_list : "https://get-subscription-dev-gen2-4kzgwodriq-df.a.run.app",
    maya_api_key : "Basic c2stbXJZSGszOVNhcE5WNWNGWDlyaFFBd2ZzRmZHcDlJNENNcVRNMURpOGI2aTo=",
    zipph_process_subscription : "https://process-payment-zipph-dev-gen2-4kzgwodriq-df.a.run.app",
    zipph_process_subscription_status : "https://process-payment-status-zipph-dev-gen2-4kzgwodriq-df.a.run.app",
    zipph_api_key : "Basic c2tfbGl2ZV8yZkY4Tkc1M2g3RTRlR3NzNXZZQ045Og==",

    cancel_subscription : "https://cancel-subscription-zipph-dev-gen2-4kzgwodriq-df.a.run.app",
    get_subscription_billing : "https://get-subscription-billing-zipph-dev-gen2-4kzgwodriq-df.a.run.app",
    process_bulk_wiki : "https://process-bulk-search-wiki-dev-gen2-4kzgwodriq-df.a.run.app",
    process_bulk_adverse : "https://process-bulk-search-adverse-dev-gen2-4kzgwodriq-df.a.run.app",
    process_csv_file_upload : "https://process-csv-file-upload-dev-gen2-4kzgwodriq-df.a.run.app",
    //elastic_index : "test",
    //elastic_api_key : "ApiKey dDFZLV9Ja0JaVjFRRXNOVElQcF86d1hEZ0d3OFpSR1d5LUhwbG9tVFBQUQ==",
    
    //collection
    individual : "individual_dev",
    entity : "entity_dev",
    setting : "setting_dev",
    dashboard_counter :"dashboard_counter_dev",
    dashboard_counter_wiki :"dashboard_counter_wiki_dev",
    dashboard_counter_adverse : "dashboard_counter_adverse_dev",
    user_management : "user_management_dev",
    source_list : "source_list_dev",
    entity_source_list : "entity_source_list_dev",
    nationality_list : "nationality_list_dev",
    storage_folder  : "u_check_admin_dev",
    user_permission : "user_permission_dev",
    support : "support_dev",
    generated_manifest : "generated_manifest_dev",
    company_information : "company_information_dev",
    bulk_search : "bulk_counter_dev",
    bulk_counter_wiki : "bulk_counter_wiki_dev",
    bulk_counter_adverse : "bulk_counter_adverse_dev",
    team : "team_dev",
    subscription_specs : "subscription_specs_dev",
    //end collection

    //support email
    support_email : "plangaman@unawa.asia",
    //support_email : "techsupport@unawa.asia",
    //support_email : "mligaoen@unawa.asia",
    from_email : "no-reply@unawa.asia",
    //end support email
    //app version
    app_version : "ver. 1.1.4"
}