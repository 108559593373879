<!--
<ul class="nav flex-column">
    <li class="nav-item" >
        <a class="nav-link active  text-white" aria-current="page" href="#">Active</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-white" [routerLink]="['/main/search']">Search User</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-white" href="javascript: void(0);">Settings</a>
    </li>
    
</ul>
<hr class="text-white"/>
<ul class="nav flex-column">
    <li class="nav-item">
        <a class="nav-link text-white" href="javascript: void(0);" (click)="logout()">Logout</a>
    </li>
</ul>
-->
<div class="d-flex align-items-center justify-content-evenly align-content-center mb-4 sidemenuprofile">
    <div id="avatar">
        <img *ngIf="global.login_session['profile_photo'] === undefined || global.login_session['profile_photo'] === ''" id="profile_pic" src="../../assets/images/dashboard-user-profile.svg" class="main-profile-img" />
        <img *ngIf="global.login_session['profile_photo'] !== undefined && global.login_session['profile_photo'] !== ''" id="profile_pic" src="{{global.login_session['profile_photo']}}" class="main-profile-img" />
    </div>
    <div class="profiledetails">
        <h5 class="profilename">Hello, {{global.login_session["first_name"]}} {{global.login_session["last_name"]}}!</h5>
        <div class="d-flex align-items-center justify-content-between">
            <p class="plandetail mb-0">{{this.subscription["subscription_name"] !== undefined? (this.subscription["subscription_name"]) : ""}}</p>
            <a class="viewaccountbtn" href="/main/account" (click)="toggleClass($event,'Account')">View Account</a>
        </div>
    </div>
</div>

<ul class="nav flex-column">
    <li #dashboard [ngClass]="{'selected': selectedItem == 'dashboard'}" class="nav-item pt-2 pb-2" >
        <div class="container-fluid">
            <!-- <a class="nav-link text-white text-break" aria-current="page" href="javacsript: void(0)" (click)="toggleClass($event,'Dashboard')"  [routerLink]="['/main/dashboard']">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/Icons.svg"> Dashboard
            </a> -->
            <a class="side_logo nav-link text-white text-break" aria-current="page" href="/main/dashboard" (click)="toggleClass($event,'Dashboard')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'dashboard' ? '../../../assets/images/Icons-inverted.svg' : '../../../assets/images/Icons.svg'"> <span class="side-label">Dashboard</span>
            </a>
        </div>
        
    </li>
    <li #search class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'search'}" *ngIf="global.login_session['permission']['search'] !== undefined">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/search']" (click)="toggleClass($event,'Search')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/Frame 5587.svg"> Search
            </a> -->
            <a class="side_logo nav-link text-white" href="/main/search" (click)="toggleClass($event,'Search')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'search' ? '../../../assets/images/Frame 5587-inverted.svg' : '../../../assets/images/Frame 5587.svg'"> <span class="side-label">Search</span>
            </a>
        </div>
        
    </li>

    <!-- <li #account class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'account'}" *ngIf="global.login_session['permission']['account'] !== undefined">
        <div class="container-fluid">
             <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/account']" (click)="toggleClass($event,'Account')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/005-user 1.svg"> Account
            </a> 
            <a class="side_logo nav-link text-white" href="/main/account" (click)="toggleClass($event,'Account')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/005-user 1.svg"> <span class="side-label">Account</span>
            </a>
        </div>
    </li> -->

    <li #audit_trail class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'audit_trail'}" *ngIf="global.login_session['permission']['audit_trail'] !== undefined && ((this.global.login_session['subscription_billing']['subscription'] !== undefined && this.global.login_session['subscription_billing']['status'] == 'running') || global.login_session['user_type'] == 'super_admin')">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/audit_trail']" (click)="toggleClass($event,'Audit Trail')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/audit_trail.svg"> Audit Trail
            </a> -->
            <a class="side_logo nav-link text-white" href="/main/audit_trail" (click)="toggleClass($event,'Audit Trail')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'audit_trail' ? '../../../assets/images/audit_trail-inverted.svg' : '../../../assets/images/audit_trail.svg'"> <span class="side-label">Audit Trail</span>
            </a>
        </div>
    </li>


    <li #generated_manifest class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'generated_manifest'}" *ngIf="global.login_session['permission']['generated_manifest'] !== undefined && ((this.global.login_session['subscription_billing']['subscription'] !== undefined && this.global.login_session['subscription_billing']['status'] == 'running') || global.login_session['user_type'] == 'super_admin')">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/generated_manifest']" (click)="toggleClass($event,'Reports')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/reports.svg"> Reports
            </a> -->
            <a class="side_logo nav-link text-white" href="/main/generated_manifest" (click)="toggleClass($event,'Reports')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'generated_manifest' ? '../../../assets/images/reports-inverted.svg' : '../../../assets/images/reports.svg'"> <span class="side-label">Reports</span>
            </a>
        </div>
    </li>

    <li #faq class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'faq'}" *ngIf="global.login_session['permission']['faq'] !== undefined">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/faq']" (click)="toggleClass($event,'FAQ')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/faq.svg"> FAQ
            </a> -->
            <a class="side_logo nav-link text-white" href="/main/faq" (click)="toggleClass($event,'FAQ')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'faq' ? '../../../assets/images/faq-inverted.svg' : '../../../assets/images/faq.svg'"> <span class="side-label">FAQ</span>
            </a>
            <!--<a class="nav-link text-white disabled" href="javascript: void(0);" (click)="toggleClass($event,'FAQ')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/faq.svg"> FAQ
            </a>-->
        </div>
    </li>

    <li #support class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'support'}" *ngIf="global.login_session['permission']['support'] !== undefined">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/support']" (click)="toggleClass($event,'Support')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/support.svg"> Support
            </a> -->
            <a class="side_logo nav-link text-white" href="/main/support" (click)="toggleClass($event,'Support')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/support.svg"> <span class="side-label">Support</span>
            </a>
        </div>
    </li>

    

    

    
    
    <li #settings class="nav-item pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'settings'}" *ngIf="global.login_session['permission']['settings'] !== undefined">
        <div class="container-fluid">
           <!-- <a class="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#sub_setting" href="javascript: void(0);" [routerLink]="['/main/settings']" (click)="toggleClass($event,'Settings')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/Icons-1.svg"> Settings
            </a> -->

            <a class="side_logo nav-link text-white" href="/main/settings" (click)="toggleClass($event,'Settings')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/Icons-1.svg"> <span class="side-label">Settings</span>
            </a>
        </div>
        
    </li>

    <div #source class="collapse ps-4 d-none pt-2 pb-2" [ngClass]="{'selected': selectedItem == 'source'}" id="sub_setting">
        <li class="nav-item col">
            <div class="container-fluid">
                <a class="nav-link text-white" href="javascript: void(0);"  (click)="toggleClass($event,'Source')">Source</a>
            </div>
            
        </li>

        
    </div>

    <li class="nav-item col gx-0">
        <hr class="text-white mx-auto" style="max-width: 83%;"/>
    </li>

    <p class="admintext mt-4 mb-0">Admin Menu</p>

    <li #user_management [ngClass]="{'selected': selectedItem == 'user_management'}" class="nav-item pt-2" *ngIf="global.login_session['permission']['manage_user'] !== undefined && ((this.global.login_session['subscription_billing']['subscription'] !== undefined && this.global.login_session['subscription_billing']['status'] == 'running') || global.login_session['user_type'] == 'super_admin')">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/user_management']" (click)="toggleClass($event,'Manage users')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/manage-user.svg"> Manage users
            </a> -->

            <a class="side_logo nav-link text-white" href="/main/user_management" (click)="toggleClass($event,'Manage users')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'user_management' ? '../../../assets/images/manage-user-inverted.svg' : '../../../assets/images/manage-user.svg'"> <span class="side-label">Manage users</span>
            </a>
        </div>
        
    </li>

    <li #manage_groups [ngClass]="{'selected': selectedItem == 'manage_groups'}" class="d-none nav-item" *ngIf="global.login_session['permission']['manage_groups'] !== undefined">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/user_management']" (click)="toggleClass($event,'Manage users')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/manage-user.svg"> Manage users
            </a> -->

            <a class="side_logo nav-link text-white" href="/main/manage-groups" (click)="toggleClass($event,'Manage Groups')">
                <img style="width:0.2in;" class="img-fluid me-3" [src]="selectedItem === 'manage_groups' ? '../../../assets/images/manage-user-inverted.svg' : '../../../assets/images/manage-user.svg'"> <span class="side-label">Manage Groups</span>
            </a>
        </div>
        
    </li>
    

    <li #audit_log class="nav-item pb-2" [ngClass]="{'selected': selectedItem == 'audit_log'}" *ngIf="global.login_session['permission']['audit_log'] !== undefined && ((this.global.login_session['subscription_billing']['subscription'] !== undefined && this.global.login_session['subscription_billing']['status'] == 'running') || global.login_session['user_type'] == 'super_admin')">
        <div class="container-fluid">
            <!-- <a class="nav-link text-white" href="javascript: void(0);" [routerLink]="['/main/audit_log']" (click)="toggleClass($event,'Audit Log')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/audit_log.svg"> Audit Log
            </a> -->

            <a class="side_logo nav-link text-white" href="/main/audit_log" (click)="toggleClass($event,'Audit Log')">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/audit_log.svg"> <span class="side-label">Audit Log</span>
            </a>
        </div>
    </li>

    <li class="nav-item pt-2 pb-2">
        <div class="container-fluid">
            <a class="side_logo nav-link text-white" href="javascript: void(0);" (click)="logout()">
                <img style="width:0.2in;" class="img-fluid me-3" src="../../../assets/images/008-logout 1.svg"> <span class="side-label" style="font-size: 18px; font-weight: 500;">Log Out</span>
            </a>
        </div>
        
    </li>

</ul>
