import { Component } from '@angular/core';
import { MultifactorAuth } from '../services/2fa-service';
import { signInWithCredential,signInWithPhoneNumber, multiFactor, RecaptchaVerifier, getAuth, getMultiFactorResolver, signInWithEmailAndPassword, PhoneMultiFactorGenerator, PhoneAuthProvider} from "firebase/auth";
import { FirebaseApp } from '@angular/fire/app';
import { FormBuilder, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-multifactor',
  templateUrl: './multifactor.component.html',
  styleUrls: ['./multifactor.component.scss']
})
export class MultifactorComponent {
	
	public select_data_list = new Array();
	private recaptchaVerifier : any;
	private recaptchaVerifier2 : any;
	private phoneInfoOptions : any;
	public vID = null;
	private user = null;
	private errorSignin : {}
	form = this.fb.group({
		selectlist : ['',Validators.required],
		verificationCode : ['']
	})
	constructor(
		private multifactorAuth: MultifactorAuth,
		private fbApp : FirebaseApp,
		private fb : FormBuilder
	){

	}
	ngOnInit(){
		
		/*
		
		const auth = getAuth(this.fbApp);
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
			"size" : "normal",
			'callback': (response) => {
				console.log(response);

				signInWithEmailAndPassword(auth, 'plangaman+2@unawa.asia', 'Admin1234')
					.then(function (userCredential) {
						multiFactor(userCredential.user).getSession().then(function (multiFactorSession) {
							const phoneInfoOptions = {
								phoneNumber: '+639171551303',
								session: multiFactorSession
							};
							const phoneAuthProvider = new PhoneAuthProvider(auth);
							// Send SMS verification code.
							return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
						})
					})
				

				
				
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				// ...
			}
		}, auth);
		recaptchaVerifier.render();
		*/
	
		
	}

	submit(){
		
		const val = this.form.value;
		this.vID = localStorage.getItem('verificationId');
		console.log('this.vID',this.vID);
		if(this.vID !== null && this.vID !== undefined){
			localStorage.removeItem('verificationId');
			const cred = PhoneAuthProvider.credential(this.vID, val.verificationCode);
			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
			multiFactor(this.user).enroll(multiFactorAssertion, "My personal phone number");
			console.log('success');
		}//end if
	}

	async submit2(){
		const auth = getAuth(this.fbApp);
		const val = this.form.value;
		this.vID = localStorage.getItem('verificationId');
		console.log('this.vID2',this.vID);
		if(this.vID !== null && this.vID !== undefined){
			
			const cred = PhoneAuthProvider.credential(this.vID, val.verificationCode);
			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

			const resolver = getMultiFactorResolver(auth, JSON.parse(JSON.stringify(this.errorSignin)));
			resolver.resolveSignIn(multiFactorAssertion)
			.then(function (userRecord) {
				console.log('success',userRecord.user);
				// userCredential will also contain the user, additionalUserInfo, optional
				// credential (null for email/password) associated with the first factor sign-in.

				// For example, if the user signed in with Google as a first factor,
				// userCredential.additionalUserInfo will contain data related to Google
				// provider that the user signed in with.
				// - user.credential contains the Google OAuth credential.
				// - user.credential.accessToken contains the Google OAuth access token.
				// - user.credential.idToken contains the Google OAuth ID token.
			});
		}//end if
	}

	async test(){
		const request_param = {
			"email" : "plangaman+8@unawa.asia",
			"password" : "Admin1234",
			"phone" : "+639517624489"
		}
		this.multifactorAuth.setAuth();
		const result = await this.multifactorAuth.sign_in(request_param);
		console.log('result done',result);
		if(result["success"]){
			if(result["emailVerified"]){

			}else{
				console.log("success",result["data"]["message"]);
			}
		}else{
			console.log(result["data"])
		}
		
		/*const auth = getAuth(this.fbApp);

		var user = {
			"kind": "identitytoolkit#VerifyPasswordResponse",
			"localId": "zhFR0R3zWFOzXNgRJzuP7v0mXmf1",
			"email": "plangaman+1@unawa.asia",
			"displayName": "undefined undefined",
			"idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjhkOWJlZmQzZWZmY2JiYzgyYzgzYWQwYzk3MmM4ZWE5NzhmNmYxMzciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoidW5kZWZpbmVkIHVuZGVmaW5lZCIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9jc3RmLXYyIiwiYXVkIjoiY3N0Zi12MiIsImF1dGhfdGltZSI6MTcyOTAzNzcwMywidXNlcl9pZCI6InpoRlIwUjN6V0ZPelhOZ1JKenVQN3YwbVhtZjEiLCJzdWIiOiJ6aEZSMFIzeldGT3pYTmdSSnp1UDd2MG1YbWYxIiwiaWF0IjoxNzI5MDM3NzAzLCJleHAiOjE3MjkwNDEzMDMsImVtYWlsIjoicGxhbmdhbWFuKzFAdW5hd2EuYXNpYSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInBsYW5nYW1hbisxQHVuYXdhLmFzaWEiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.RxElU7ShKT8BIxNF1bR4Pl_sXsbMC40vyWqQjc-BZoz7Y8KxopA8CH1-QlFrdrRkBZjPhXVq_94RWt3xcIJ8zFVcV-IO6dpGhvo6Lo25e6lXi8JnVLp-ub3scGtetjCAy6d42nI2WgoVXpGjq0fimeCZ2bnwy1MsvAVdxbG61LECc2iFDmi__sOw3wSaef96qv44Xt9dVRLuahsslTT42J1VBCzd8nKaODv-CHe_41YJqGLUTp3tfa_lgczVPwf3I-TsmlikutE-69J50n63ItUo22mfBr7lUgV_a9q5G6Dbq_QDgFtL1PA1GlCoNerEmAoGNYe6m6koltnth-tIAg",
			"registered": true,
			"refreshToken": "AMf-vBzRpYpK3-_bzg4HyNhlBY7QOmwW5dvHt6RTi1uqrJGulAXGyRBYJSXg1DSNjsPHYI-PPwgrJwgR74nWeZoentorAgwyCVS87mwj1v8eUoPxn1xqabU4wPigExLwmKAz849b7gtYc4ZZtJ3IXvqlLrc-k1tA5qDouLC9lyPYfzva3YPEejjYpWVtRkqEg45evOh2GzgXWCc0gRFrIHZyycmUQ2JHeUf07paoQfOu_ytKOKwSN9g",
			"expiresIn": "3600"
		}

		
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
			"size" : "invisible"
		}, auth);
		signInWithEmailAndPassword(auth, 'plangaman+2@unawa.asia', 'Admin1234').then((userRecord) => {
			console.log('user',userRecord.user);
			this.user = userRecord.user;
			multiFactor(userRecord.user).getSession().then(async function (multiFactorSession) {
				const phoneInfoOptions = {
					phoneNumber: '+639517624489',
					session: multiFactorSession
				};

				const phoneAuthProvider = new PhoneAuthProvider(auth);
			
			await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
				.then((verificationId) => {
					console.log('verificationId',verificationId);

					if(verificationId !== undefined){
						localStorage.setItem('verificationId',verificationId);
					}
					
					// verificationId will be needed to complete enrollment.
				}).catch((e) => {
					recaptchaVerifier.clear();
					console.log('error',e);
				});
			});
		});
		*/
		
		

		/*const auth = getAuth(this.fbApp);
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
			"size" : "invisible"
		}, auth);
		//recaptchaVerifier.render();
		const val = this.form.value;
		signInWithEmailAndPassword(auth, 'plangaman+1@unawa.asia', 'Admin12345')
			.then(function (userCredential) {
				console.log(userCredential.user);
				// User successfully signed in and is not enrolled with a second factor.
			})
			.catch(function (error) {
				if (error.code == 'auth/multi-factor-auth-required') {
					// The user is a multi-factor user. Second factor challenge is required.
					
					const resolver = getMultiFactorResolver(auth, error);
					console.log('sfsaf',resolver.hints);

					if (resolver.hints[0].factorId ===
						PhoneMultiFactorGenerator.FACTOR_ID) {
						// User selected a phone second factor.
						const phoneInfoOptions = {
							multiFactorHint: resolver.hints[0],
							session: resolver.session
						};
						
						const phoneAuthProvider = new PhoneAuthProvider(auth);
						
						//console.log('phoneAuthProvider',phoneAuthProvider);
						return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(function (verificationId) {
							const cred = PhoneAuthProvider.credential(
								verificationId, val.verificationCode);
							const multiFactorAssertion =
								PhoneMultiFactorGenerator.assertion(cred);
							// Complete sign-in.
							return resolver.resolveSignIn(multiFactorAssertion);
						}).then(function (userCredential) {
							// User successfully signed in with the second factor phone number.\
							return false;
						});
								
						
					}
					
					//this.select_data_list = resolver.hints

					// ...
				}
				
				return false;
				//end if
			
		});
		*/
	}

	testbak(){
		
		const auth = getAuth(this.fbApp);
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
			"size" : "invisible"
		}, auth);
		//recaptchaVerifier.render();
		const val = this.form.value;
		signInWithEmailAndPassword(auth, 'plangaman+1@unawa.asia', 'Admin12345')
			.then(function (userCredential) {
				// User successfully signed in and is not enrolled with a second factor.
			})
			.catch(function (error) {
				if (error.code == 'auth/multi-factor-auth-required') {
					// The user is a multi-factor user. Second factor challenge is required.
					
					signInWithPhoneNumber(auth, '+639171551303', recaptchaVerifier)
					.then((confirmationResult) => {
					// SMS sent. Prompt user to type the code from the message, then sign the
					// user in with confirmationResult.confirm(code).
						console.log('confirmationResult',confirmationResult.verificationId);
					// ...
					}).catch((error) => {
					// Error; SMS not sent
					// ...
					});
					//this.select_data_list = resolver.hints

					// ...
				}
				
				return false;
				//end if
			
		});
	}



	test2(){
		
		const auth = getAuth(this.fbApp);
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
			"size" : "invisible"
		}, auth);
		const val = this.form.value;
		signInWithEmailAndPassword(auth, 'plangaman+2@unawa.asia', 'Admin1234')
			.then(function (userCredential) {
				
			})
			.catch((error) => {
				this.errorSignin = error;
				if (error.code == 'auth/multi-factor-auth-required') {
					console.log('error.code',error.code);
					// The user is a multi-factor user. Second factor challenge is required.
					const resolver = getMultiFactorResolver(auth, error);
					

					if (resolver.hints[0].factorId ===
						PhoneMultiFactorGenerator.FACTOR_ID) {
						// User selected a phone second factor.
						const phoneInfoOptions = {
							multiFactorHint: resolver.hints[0],
							session: resolver.session,
							
						};
						const phoneAuthProvider = new PhoneAuthProvider(auth);
						console.log('phoneAuthProvider',phoneAuthProvider)
						phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then((verificationId) => {
							console.log('verificationId2',verificationId)
							if(verificationId !== undefined){
								localStorage.setItem('verificationId',verificationId);
							}
						});
						/*return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(function (verificationId) {
							const cred = PhoneAuthProvider.credential(
								verificationId, val.verificationCode);
							const multiFactorAssertion =
								PhoneMultiFactorGenerator.assertion(cred);
							// Complete sign-in.
							return resolver.resolveSignIn(multiFactorAssertion)
						}).then(function (userCredential) {
							// User successfully signed in with the second factor phone number.\
							console.log('userCredential',userCredential.user);
						});*/
								
						
					}
					
					//this.select_data_list = resolver.hints

					// ...
				}//end if

				return error;
			});
	}


}
