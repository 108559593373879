

<div class="container-fluid">
    

    <div class="row ms-3 me-3">
        <div class="col">
           <div class="card py-2 shadow">
                <div class="card-body">
                    
                    <div class="row align-items-end">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-auto">
                            Filter by
                            <form [formGroup]="form" class="mt-2">
                                <div class="row gx-2">
                                    <div class="col">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input filter_grp1" formControlName="filter_weekly" type="checkbox" (change)="check($event,'filter_weekly')">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                This week
                                            </label>
                                        </div>
                    
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" formControlName="filter_monthly"  type="checkbox" (change)="check($event,'filter_monthly')">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                This month
                                            </label>
                                        </div>
                    
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" formControlName="filter_yearly"  type="checkbox" (change)="check($event,'filter_yearly')">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                This year
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                            <small>Date</small>
                            <form [formGroup]="form">
                                <div class="row gx-2 align-items-center">
                                    <div class="col-auto">
                                        From
                                    </div>
                                    <div class="col">
                                        <div class="input-group">
                                            <ngx-datepicker #filter_date_from formControlName="filter_date_from" (ngModelChange)="get_date_from($event)" [options]="date_option"></ngx-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        To
                                    </div>
                                    <div class="col">
                                        <div class="input-group">
                                            <ngx-datepicker #filter_date_to class="input-field" formControlName="filter_date_to" (ngModelChange)="get_date_to($event)" [options]="date_option"></ngx-datepicker>
                                        </div>
                                    </div>

                                   
                                    <div class="col">
                                        <div class="input-group" style="height: 35px; margin-top: -2.3rem;">
                                            <!-- <select class="form-select" formControlName="filter_user">
                                                
                                                <option value="">All user</option>
                                                <option *ngFor="let data of select_data_list" value="{{data.id}}">{{data.first_name}} {{data.last_name}}</option>
                                            </select> -->
                                            <mat-form-field class="auditlog-field">
                                                <input type="text" matInput placeholder="Search user"
                                                       [value]="selectedUserName" (input)="onUserInput($event)"
                                                       [matAutocomplete]="auto">
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelected($event)">
                                                    <mat-option *ngFor="let data of filteredUsers | async" [value]="data">
                                                        {{ data.first_name }} {{ data.last_name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xxl-2 col-auto">
                                    
                                        <div class="d-grid gap-2">
                                            <button type="button" [disabled]="disable_filter" class="btn btn-primary font-700" (click)="search()">Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                    

                  

                    
                </div>

           </div>
        </div>
    </div>

    <div class="row justify-content-center ms-3 me-3" *ngIf="show_total_search_result">
        <div class="col">
           
                <p class="fw-bold mt-4 mb-4">{{lib.toCurrency_precision(total_search_result,0)}} result(s)</p>
                
             
                
                <div class="shadow bg-white rounded">
                    <div class="table-responsive" style="max-height:500px;">
                        <table class="table" style="min-width:2in;">
                            <thead>
                                <tr>
                                    
                                    <th class="text-muted text-center px-3">No.</th>
                                    <th class="text-muted text-left px-3">User</th>
                                    <!-- <th class="text-muted text-left px-3">Searched words/names</th> -->
                                    <th class="text-muted text-center px-3">Total of matched results</th>
                                    <th class="text-muted text-left px-3">Searched on</th>
                                    <th class="text-muted text-center px-3">With Hit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of data_list; let x = index">
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3">{{data.profile.first_name}} {{data.profile.last_name}}</td>
                                    <!-- <td class="text-left px-3">{{data.searched_value}}</td> -->
                                    <td class="text-center px-3">{{lib.toCurrency_precision(data.query_result,0)}}</td>
                                    <td class="text-left px-3">{{data.date_created_str}}</td>
                                    <td class="text-center px-3">
                                        <span class="{{data.query_result > 0? 'text-success' : ''}}">{{data.query_result > 0? 'Yes' : 'No'}}</span>    
                                    </td>
                                    
                                    
                                </tr>
                            </tbody>
                            
                            
                        </table>

                    </div>

                    <div class="row container-fluid justify-content-end align-items-center mt-4">
                        <div class="col-auto">
                            <form [formGroup]="form" *ngIf="show_total_search_result">

                                <div class="row mb-3 align-items-center">
                                    <label for="inputPassword" class="col-auto col-form-label font-14">
                                        Rows per page
                                    </label>
                                    <div class="col">
                                        <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                            <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-auto">
                            <div class="mb-3 row align-items-center">
                                <div class="col-auto">
                                    
                                    {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="col-auto">
                            <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                <ul class="pagination border-opacity-10">
                                    <li class="page-item " (click)="prev()" >
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                        </a>
                                    </li>
                                
                                    <li class="page-item" (click)="next()">
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        

                        <div class="col-auto" *ngIf="show_download_btn">
                            <div class="d-grid gap-2 mb-3">
                                <button type="button" class="btn btn-primary btn-sm" (click)="download_log_all()">
                                    Download Log
                                    <img src="../../assets/images/add.svg" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
