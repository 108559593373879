

<div class="navbar_float_left desktopside" style="background-color: #FFFFFF;">
    <div class="v2-color-side-middle h-100" style="border-radius: 0px 30px 30px 0px;">
        <div style="min-height: 0.76in;">
            <div class="pt-4 pb-3 px-5">
                <img class="img-fluid" style="max-width: 206px; width: 100%;" src="../../assets/images/ucheck_logo2.png">
            </div>
        
        </div>
        
        <div class="pt-3" style="padding-left: 1rem;">
            <app-side-menu></app-side-menu>
        </div>

        <div class="valign bottom">
            <div class="text-center">
                <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                    <a href="https://unawa.asia/terms-conditions" class="text-white terms-conditions">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white privacy-policy">Privacy Policy</a>
                </small><br/>
                <small>
                    <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white unawa-link"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                </small><br/>
                <small>
                    <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                </small>
            </div>
            
        </div>

        <div class="collapsebtn">
            <img class="" src="../../../assets/images/hide flap.svg">
        </div>
    </div>
</div>

<div class="navbar_float_right bg-light shadow" style="min-height: 0.76in;">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-auto">
                <nav class="navbar navbar-expand-md pt-3">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div class="v2-color-side-middle offcanvas offcanvas-start collapse" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header" style="border-bottom: 1px solid white;">
                            <div style="min-height: 0.76in;">
                                <div class="pt-3 ps-5">
                                    <img class="img-fluid" src="../../assets/images/uchech logo.svg">
                                </div>
                               
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <app-side-menu></app-side-menu>

                            <div class="pt-5" style="height:10% !important;">
                                <p class="text-center">
                                    <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                                        <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                                    </small>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-auto">
                <div class="pt-4">
                    <h4>{{global.title}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="router_body">
        
    <router-outlet></router-outlet>
   
</div>

<div class="row d-none" style="min-height: 11%;">
    <div class="v2-color-side-top col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block gx-0" style="min-width: 1.2in;">
        <!-- <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block bg-dark-green2 gx-0" style="min-width: 1.2in;"></div> -->
        <div class="container-fluid pt-3 ps-5">
            <img  class="img-fluid" src="../../assets/images/uchech logo.svg">
        </div>
        <hr class="text-white"/>
    </div>

    <div class="col-xxl-10 col-xl-10 col-lg-10 col">
        
        <app-header-menu></app-header-menu>
    </div>
</div>

<div class="row d-none" style="min-height: 81%;">

    <div class="v2-color-side-middle ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block " style="min-width: 1.2in;">
        <app-side-menu></app-side-menu>
    </div>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col" style="min-width: 2.5in;">
        <div class="row px-3 py-3">
            <div class="col">
                <router-outlet></router-outlet>
            </div>
        </div> 
    </div>
</div>

<div class="row d-none" style="min-height: 8%;">
    <div class="v2-color-side-bottom ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block">
        <p class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </p>
    </div>

    
</div>
