import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchService } from '../services/search-service';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ManifestService } from '../services/manifest-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-search-vw',
  templateUrl: './search-vw.component.html',
  styleUrls: ['./search-vw.component.scss']
})
export class SearchVwComponent {
    public year_today;
    public date_today;
    public app_version;
    isIncludeAdverse = 0;
    selected_tab = "pills-home";

    pageNumber                  = 0;
    row_counter                 = 1;
    pageSize                    = [1,5,10];
    pagination_counter          = 0;
    form = this.fb.group({
        page_size_placeholder : [10],
        sort_by : ["date:d"]
     
    });

    adverse_list                = {};
    adverse_list_item           = new Array();
    data_list                   = new Array();
    loading                     = false;
    show_total_search_result    = false;
    total_search_result         = 0;
    snapshot_param              = {}; //snapshot parameter

    //
    adverse_parameter_list = new Array();
    form_filter_adverse = this.fb.group({
        filter_adverse_media_topic : [''],
        page_size_placeholder : [10],
        sort_by : ["date:d"]
    });
    constructor(
        private searchService : SearchService,
        private _activatedRouter : ActivatedRoute,
        public commonService : CommonService,
        public global : Globals,
        private datePipe : DatePipe,
        private fb : FormBuilder,
        private manifestService : ManifestService,
        private toastr : ToastrService
    ){
        this.snapshot_param = this._activatedRouter.snapshot.params;
        this.isIncludeAdverse = parseInt(this.snapshot_param['isIncludeAdverse']);
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();
        this.app_version = environment.app_version; 
    }//end constructor

    async ngOnInit(){
        this.global.loading = true;
        try{
            let val = this.form.value;
            let request_param = new Array();
            request_param.push(lastValueFrom(this.load_adverse_parameter()));
            request_param.push(lastValueFrom(this.refresh_list2(this.snapshot_param["id"])));

            

            Promise.allSettled(request_param).then(async (r) => {
                r.forEach((res, index) => {
                    if(res.status === 'fulfilled') {
                        if(index == 0){
                            //-------------------------------adverse param-------------------------------
                            const result = res.value;
                            if(result["success"]){
                                for(const k in result["data"]["hits"]){
                                    const map_data = result["data"]["hits"][k];
                                    this.adverse_parameter_list.push({
                                        "id" : map_data["_source"]["name"],
                                        "name" : map_data["_source"]["name"],
                                    });
                                }//end for
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(result["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
                            
                            //-------------------------------adverse param-------------------------------
                        }else if(index == 1){
                            //-------------------------------profile-------------------------------
                            const elastic_api = res.value;
                            if(elastic_api["success"]){
                                var hits = elastic_api["data"]["hits"];
                                if(hits["total"]["value"] > 0){
                                    for(const k in hits["hits"]){
                                        var map_data = hits["hits"][k];
                    
                                        if(this.snapshot_param['search_type'] == 'entity'){
                                            map_data["_source"]['FULL_NAME'] = map_data["_source"]['FIRST_NAME'];
                                        }//end if
                    
                                        if(this.snapshot_param['search_type'] == 'individual'){
                    
                                            var full_name = map_data["_source"]['FULL_NAME'];
                                            var family_name = '';
                    
                                            
                                            if(map_data["_source"]['FOURTH_NAME'] !== undefined){
                                                family_name = map_data["_source"]['FOURTH_NAME'];
                                            }else if(map_data["_source"]['THIRD_NAME'] !== undefined){
                                                family_name = map_data["_source"]['THIRD_NAME'];
                                            }else if(map_data["_source"]['SECOND_NAME'] !== undefined){
                                                family_name = map_data["_source"]['SECOND_NAME'];
                                            }//end if
                                            map_data["_source"]["FAMILY_NAME"] = family_name;
                                        }//end if
                    
                                        if(map_data["_source"]["INDIVIDUAL_ALIAS"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_ALIAS_PARSED"] = map_data["_source"]["INDIVIDUAL_ALIAS"];
                                        }
                    
                                        if(map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH_PARSED"] = map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH"];
                                        }
                    
                                        if(map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH_PARSED"] = map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH"];
                                        }
                                        
                                        
                                        if(map_data["_source"]["ENTITY_ALIAS"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_ALIAS_PARSED"] = map_data["_source"]["ENTITY_ALIAS"];
                                        }
                    
                                        if(map_data["_source"]["NATIONALITY"] !== undefined){
                                            map_data["_source"]["NATIONALITY_PARSED"] = map_data["_source"]["NATIONALITY"];
                                        }
                    
                                        if(map_data["_source"]["INDIVIDUAL_DOCUMENT"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_DOCUMENT_PARSED"] = map_data["_source"]["INDIVIDUAL_DOCUMENT"];
                                        }
                    
                    
                                        if(map_data["_source"]["INDIVIDUAL_ADDRESS"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_ADDRESS_PARSED"] = map_data["_source"]["INDIVIDUAL_ADDRESS"];
                                        }
                    
                                        if(map_data["_source"]["ENTITY_ADDRESS"] !== undefined){
                                            map_data["_source"]["INDIVIDUAL_ADDRESS_PARSED"] = map_data["_source"]["ENTITY_ADDRESS"];
                                        }
                    
                                        if(map_data["_source"]["DESIGNATION"] !== undefined){
                                            map_data["_source"]["DESIGNATION_PARSED"] = map_data["_source"]["DESIGNATION"];
                                        }
                    
                                        if(map_data["_source"]["LISTED_ON"] !== undefined){
                                            map_data["_source"]["LISTED_ON_PARSED"] = this.datePipe.transform(map_data["_source"]["LISTED_ON"], 'MMM dd, yyyy');
                                        }
                    
                                        if(map_data["_source"]["LAST_DAY_UPDATED"] !== undefined){
                                            map_data["_source"]["LAST_DAY_UPDATED_PARSED"] = map_data["_source"]["LAST_DAY_UPDATED"];
                                            for(var s in map_data["_source"]["LAST_DAY_UPDATED_PARSED"]){
                                                var mm_data = map_data["_source"]["LAST_DAY_UPDATED_PARSED"][s];
                                                mm_data = this.datePipe.transform((mm_data), 'MMM dd, yyyy');
                    
                                                map_data["_source"]["LAST_DAY_UPDATED_PARSED"][s] = mm_data;
                                            }//end for
                                        }//end if
                    
                                        
                                        this.data_list.push(
                                            map_data["_source"]
                                        );
                                        
                                    }//end for
                                    this.show_total_search_result   = true;
                                }else{
                                    this.data_list                  = [];
                                    this.show_total_search_result   = true;
                                }//end if
                                
                              
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(elastic_api["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
                            //-------------------------------profile-------------------------------
                        }//end if
                    }//end if
                });
                if(parseInt(this.snapshot_param['isIncludeAdverse'])){
                    if(this.snapshot_param["adverse_topic"] !== undefined){
                        this.form_filter_adverse.controls['filter_adverse_media_topic'].setValue(this.snapshot_param["adverse_topic"]);
                    }//end if

                   await this.load_adverse();
                    //request_param.push(this.load_adverse_promise());
                }//end if

                this.global.loading = false;
            })
        }catch(e : any) {
			console.log(JSON.stringify(e));
			this.toastr.error("Failed to generate report!","System Message");
		}//end catch


    }

    async ngOnInit3(){
        this.global.loading    = true;
        //load data
        //========================================================================================
        const elastic_api = await lastValueFrom(this.refresh_list2(this.snapshot_param["id"]));
        console.log('elastic_api',elastic_api);
        //alert(this.snapshot_param["adverse_topic"]);
        if(elastic_api["success"]){
            var hits = elastic_api["data"]["hits"];
            if(hits["total"]["value"] > 0){
                for(const k in hits["hits"]){
                    var map_data = hits["hits"][k];

                    if(this.snapshot_param['search_type'] == 'entity'){
                        map_data["_source"]['FULL_NAME'] = map_data["_source"]['FIRST_NAME'];
                    }//end if

                    if(this.snapshot_param['search_type'] == 'individual'){

                        var full_name = map_data["_source"]['FULL_NAME'];
                        var family_name = '';

                        
                        if(map_data["_source"]['FOURTH_NAME'] !== undefined){
                            family_name = map_data["_source"]['FOURTH_NAME'];
                        }else if(map_data["_source"]['THIRD_NAME'] !== undefined){
                            family_name = map_data["_source"]['THIRD_NAME'];
                        }else if(map_data["_source"]['SECOND_NAME'] !== undefined){
                            family_name = map_data["_source"]['SECOND_NAME'];
                        }//end if
                        map_data["_source"]["FAMILY_NAME"] = family_name;
                    }//end if

                    if(map_data["_source"]["INDIVIDUAL_ALIAS"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_ALIAS_PARSED"] = map_data["_source"]["INDIVIDUAL_ALIAS"];
                    }

                    if(map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH_PARSED"] = map_data["_source"]["INDIVIDUAL_PLACE_OF_BIRTH"];
                    }

                    if(map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH_PARSED"] = map_data["_source"]["INDIVIDUAL_DATE_OF_BIRTH"];
                    }
                    
                    
                    if(map_data["_source"]["ENTITY_ALIAS"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_ALIAS_PARSED"] = map_data["_source"]["ENTITY_ALIAS"];
                    }

                    if(map_data["_source"]["NATIONALITY"] !== undefined){
                        map_data["_source"]["NATIONALITY_PARSED"] = map_data["_source"]["NATIONALITY"];
                    }

                    if(map_data["_source"]["INDIVIDUAL_DOCUMENT"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_DOCUMENT_PARSED"] = map_data["_source"]["INDIVIDUAL_DOCUMENT"];
                    }


                    if(map_data["_source"]["INDIVIDUAL_ADDRESS"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_ADDRESS_PARSED"] = map_data["_source"]["INDIVIDUAL_ADDRESS"];
                    }

                    if(map_data["_source"]["ENTITY_ADDRESS"] !== undefined){
                        map_data["_source"]["INDIVIDUAL_ADDRESS_PARSED"] = map_data["_source"]["ENTITY_ADDRESS"];
                    }

                    if(map_data["_source"]["DESIGNATION"] !== undefined){
                        map_data["_source"]["DESIGNATION_PARSED"] = map_data["_source"]["DESIGNATION"];
                    }

                    if(map_data["_source"]["LISTED_ON"] !== undefined){
                        map_data["_source"]["LISTED_ON_PARSED"] = this.datePipe.transform(map_data["_source"]["LISTED_ON"], 'MMM dd, yyyy');
                    }

                    if(map_data["_source"]["LAST_DAY_UPDATED"] !== undefined){
                        map_data["_source"]["LAST_DAY_UPDATED_PARSED"] = map_data["_source"]["LAST_DAY_UPDATED"];
                        for(var s in map_data["_source"]["LAST_DAY_UPDATED_PARSED"]){
                            var mm_data = map_data["_source"]["LAST_DAY_UPDATED_PARSED"][s];
                            mm_data = this.datePipe.transform((mm_data), 'MMM dd, yyyy');

                            map_data["_source"]["LAST_DAY_UPDATED_PARSED"][s] = mm_data;
                        }//end for
                    }//end if

                    
                    this.data_list.push(
                        map_data["_source"]
                    );
                    
                }//end for
                this.show_total_search_result   = true;
            }else{
                this.data_list                  = [];
                this.show_total_search_result   = true;
            }//end if
            
            //Load if Adverse New is SELECTED
            if(parseInt(this.snapshot_param['isIncludeAdverse'])){
              await this.load_adverse();
            }
            
            //End
        }//end if

        this.global.loading    = false;
    }//end ngOnInit

    async ngOnInit2(){
        
        this.global.loading    = true;
        
        const data_api = await lastValueFrom(this.refresh_list(this.snapshot_param["id"]));
        
        if(data_api["success"]){
            if(data_api["data"]["query_result"] !== undefined && data_api["data"]["query_result"] > 0){
                
                for(const k in data_api["data"]["query_data"]){
                    var data = data_api["data"]["query_data"][k];
                    let last_name = "";
                    

                    if(this.snapshot_param['search_type'] == 'entity'){
                        data_api["data"]["query_data"][k]['FULL_NAME'] = data_api["data"]["query_data"][k]['FIRST_NAME'];
                    }//end if
                    
                    for(const x in data){

                        if(this.snapshot_param['search_type'] == 'individual'){

                            var full_name = data_api["data"]["query_data"][k]['FULL_NAME'];
                            var family_name = '';

                            
                            if(data_api["data"]["query_data"][k]['FOURTH_NAME'] !== undefined){
                                family_name = data_api["data"]["query_data"][k]['FOURTH_NAME'];
                            }else if(data_api["data"]["query_data"][k]['THIRD_NAME'] !== undefined){
                                family_name = data_api["data"]["query_data"][k]['THIRD_NAME'];
                            }else if(data_api["data"]["query_data"][k]['SECOND_NAME'] !== undefined){
                                family_name = data_api["data"]["query_data"][k]['SECOND_NAME'];
                            }//end if



                            
                            data_api["data"]["query_data"][k]["FAMILY_NAME"] = family_name;
                        }//end if

                        
                        
                        switch (x) {
                            case "COMMENTS1" :
                                if(JSON.stringify(data_api["data"]["query_data"][k]["COMMENTS1"]) == "{}"){
                                    data_api["data"]["query_data"][k]["COMMENTS1"] = "";
                                }//end if
                                
                            break;   
                            case "INDIVIDUAL_ALIAS" :   
                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = [JSON.parse(data[x])];
                                }else{
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = data[x];
                                }//end if
                            
                            break;
                            case "ENTITY_ALIAS" :
                                    if (data[x] !== null && Array.isArray(data[x]) ) {
                                        // if array
                                        data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = data[x];
                                    }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                        //object
                                        data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = [JSON.parse(data[x])];
                                    }else{
                                        data_api["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"] = data[x];
                                    }//end if
                            
                                break;
                            case "INDIVIDUAL_DATE_OF_BIRTH" :      //birth date
                                //parse date
                                var to_parse_data;

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    to_parse_data = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    to_parse_data = [JSON.parse(data[x])];
                                }else{
                                    to_parse_data = data[x];
                                }//end if

                                for(const y in to_parse_data){
                                    
                                    if(to_parse_data[y]["DATE"] !== undefined && to_parse_data[y]["DATE"] !== ""){
                                        //to_parse_data[y]["DATE"] =  this.datePipe.transform((to_parse_data[y]["DATE"]), 'MMM dd, yyyy');
                                        to_parse_data[y]["DATE"] =  to_parse_data[y]["DATE"];
                                    }//end if
                                }//end if
                                //end parse date
                                data_api["data"]["query_data"][k]["INDIVIDUAL_DATE_OF_BIRTH_PARSED"] = to_parse_data;
                            break;
                            case "INDIVIDUAL_PLACE_OF_BIRTH" :               //parsed nationality
                                
                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_PLACE_OF_BIRTH_PARSED"] = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_PLACE_OF_BIRTH_PARSED"] = [JSON.parse(data[x])];
                                }else{
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_PLACE_OF_BIRTH_PARSED"] = data[x];
                                }//end if
                            break;

                            case "NATIONALITY" :               //parsed nationality
                                
                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["NATIONALITY_PARSED"] = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["NATIONALITY_PARSED"] = [JSON.parse(data[x])];
                                }else{
                                    data_api["data"]["query_data"][k]["NATIONALITY_PARSED"] = data[x];
                                }//end if
                                

                                //data_api["data"]["query_data"][k]["NATIONALITY_PARSED"] = JSON.parse(data[x]);
                            break;
                            
                            case 'INDIVIDUAL_DOCUMENT' :

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_DOCUMENT_PARSED"] = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_DOCUMENT_PARSED"] = [JSON.parse(data[x])];
                                }else{
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_DOCUMENT_PARSED"] = data[x];
                                }//end if

                                
                            break;

                            case 'INDIVIDUAL_ADDRESS' :

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array


                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ADDRESS_PARSED"] = data[x];
                                }//end if

                                /*else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ADDRESS_PARSED"] = [JSON.parse(data[x])];
                                }//end if
                                */
                                
                            break;

                            case 'ENTITY_ADDRESS' :

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["INDIVIDUAL_ADDRESS_PARSED"] = data[x];
                                }//end if
                                
                            break;

                            case 'DESIGNATION' :

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    data_api["data"]["query_data"][k]["DESIGNATION_PARSED"] = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    data_api["data"]["query_data"][k]["DESIGNATION_PARSED"] = [JSON.parse(data[x])];
                                }else{
                                    data_api["data"]["query_data"][k]["DESIGNATION_PARSED"] = [JSON.parse(data[x])];
                                }//end if
                                
                            break;

                            case 'LISTED_ON' :
                                data[x] = new Date(data[x]);
                                if (Object.prototype.toString.call(data[x]) === "[object Date]") {
                                    // it is a date
                                    if (isNaN(data[x])) { // d.getTime() or d.valueOf() will also work
                                        data_api["data"]["query_data"][k]["LISTED_ON_PARSED"] = "";
                                    } else {
                                        // date object is valid
                                        data_api["data"]["query_data"][k]["LISTED_ON_PARSED"] = this.datePipe.transform((data[x]), 'MMM dd, yyyy');
                                    }//end if
                                }else{
                                    data_api["data"]["query_data"][k]["LISTED_ON_PARSED"] = "";
                                }//end
                            break;

                            case "LAST_DAY_UPDATED" :      //last day updated
                                //parse date
                                var to_parse_data;

                                if (data[x] !== null && Array.isArray(data[x]) ) {
                                    // if array
                                    to_parse_data = data[x];
                                }else if(JSON.parse(data[x]) !== null && typeof JSON.parse(data[x]) === 'object'){
                                    //object
                                    to_parse_data = [JSON.parse(data[x])];
                                }else{
                                    to_parse_data = data[x];
                                }//end if

                                

                                for(const y in to_parse_data){

                                    
                                    
                                    if(to_parse_data[y] !== undefined && to_parse_data[y] !== ""){
                                        to_parse_data[y] = new Date(to_parse_data[y]);
                                        if (Object.prototype.toString.call(to_parse_data[y]) === "[object Date]") {
                                            // it is a date
                                            if (isNaN(to_parse_data[y])) { // d.getTime() or d.valueOf() will also work
                                                to_parse_data[y] = "";
                                            } else {
                                                // date object is valid
                                                to_parse_data[y] = this.datePipe.transform((to_parse_data[y]), 'MMM dd, yyyy');
                                            }//end if
                                        }else{
                                            to_parse_data[y] = "";
                                        }//end
                                    
                                    }//end if
                                }//end if
                                //end parse date
                                data_api["data"]["query_data"][k]["LAST_DAY_UPDATED_PARSED"] = to_parse_data;
                            break;

                            
                        }//end switch
                    }//end for
                }//end
                

                this.data_list                  = data_api["data"]["query_data"];
                //this.total_search_result        = data_api["data"]["query_result"];
                this.show_total_search_result   = true;
                //this.load_adverse();
            }else{
                this.data_list                  = [];
                //this.total_search_result        = 0;
                this.show_total_search_result   = true;
            }//end if
        }//end if
        //========================================================================================
        //end load data
        this.global.loading    = false;
        
    }//end ngOnInit

    refresh_list2(search_str){
        var filters = {};
        if(filters["must"] === undefined){
            filters["must"] = new Array();
        }//end if
        filters["must"].push({
            "bool" : {
                "should" : [
                    {
                        "match_phrase_prefix" : {
                            "ID" : search_str.trim()
                        }
                    }
                ]
            }
        })
        var param = {
            "from" : 0,
            "size" : 1,
            "save_search_result" : false,
            "created_by" : this.global.login_session["id"],
            "search_type" : this.snapshot_param["search_type"],
            "query" : {
                "bool" : {
                    ...filters
                }   
            }
        };
        var record_type = environment.elastic_index
        if(this.snapshot_param["search_type"] == 'individual'){
            record_type = environment.elastic_index;
        }else{
            record_type = environment.elastic_index_entity;
        }//end if

        return this.searchService.elasticsearchList(record_type,param).pipe(
            shareReplay(1)
        );
    }
    refresh_list(search_str){
           

            let filters = {};
            if(search_str.trim() !== ""){
                filters["filterFieldArr"]       = new Array();
                filters["filterOperatorArr"]    = new Array();
                filters["filterValueArr"]       = new Array();
                filters["filterFieldArr"].push("ID");
                filters["filterOperatorArr"].push("==");
                filters["filterValueArr"].push(search_str.trim());
            }//end if

            var record_type = "";
            if(this.snapshot_param["search_type"] == 'individual'){
                record_type = environment.individual;
            }else{
                record_type = environment.entity;
            }//end if

           
            
            const data_param = {
                "record_type" : record_type,
                "pageSize" : 1000,
                ...filters
            }//end

            return this.searchService.filterList(data_param);
            /*
           await table_data.subscribe((result) => {
                console.log(result);
               if(result["success"]){
                    if(result["total_result"] !== undefined && result["total_result"] > 0){
                        this.data_list = result["data"];
                        this.total_search_result        = result["total_result"];
                        this.show_total_search_result   = true;
                    }else{
                        this.data_list                  = [];
                        this.total_search_result        = 0;
                        this.show_total_search_result   = true;
                    }//end if

                    
               }//end if
               
               
            });
            */
       
    }//end if

    getTableList$(input_data) {
        return this.searchService.filterList(input_data);
    }//end getTableList
    
    go_back(){
        window.close()
    }

    selected_event : any;
    chevron_image = "../../assets/images/chevron-down.svg"

    hide_link(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_event = el;

        setTimeout(() => {
            if(this.selected_event.querySelector("img").src.indexOf('images/chevron-down.svg') >= 0){
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-up.svg';
            }else{
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-down.svg';
            }//end if
        }, 500)
        
        

       
    }

    

    load_adverse_promise(full_name){
        this.global.loading    = true;
        //var full_name = (this.data_list[0] !== undefined? this.data_list[0].FULL_NAME : "");
        const val = this.form.value;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", environment.google_custom_search_key);
        const raw = JSON.stringify({
            "name": full_name,
            "num": val.page_size_placeholder,
            "start": this.row_counter,
            "sort" : val.sort_by
        });
        console.log('raw',raw);
        var requestOptions : any; 
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        return fetch(environment.google_custom_search, requestOptions).then((response) => {
            return response.text();
        });
     
    }//end function

    async load_adverse(save_search_result : boolean = false){
        this.global.loading    = true;
        var full_name = (this.data_list[0] !== undefined? this.data_list[0].FULL_NAME : "");
        const val = this.form.value;
        const filter_adverse = this.form_filter_adverse.value;

        

        if(filter_adverse.filter_adverse_media_topic !== undefined || filter_adverse.filter_adverse_media_topic.trim() !== ''){
            full_name += " "+filter_adverse.filter_adverse_media_topic.toLocaleLowerCase();
            
        }//end if
        
        if(this.adverse_list["searchInformation"] === undefined){

        
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", environment.google_custom_search_key);
            const raw = JSON.stringify({
                "name": full_name,
                "num": val.page_size_placeholder,
                "start": this.row_counter,
                "sort" : val.sort_by,
                "save_search_result" : save_search_result
            });
            console.log('raw',raw);
            var requestOptions : any; 
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

          await fetch(environment.google_custom_search, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    this.adverse_list = JSON.parse(result);

                    if(this.adverse_list["data"]["searchInformation"]["totalResults"] > 0){
                        //console.log(this.adverse_list["data"]["searchInformation"]["totalResults"])
                        this.adverse_list_item = this.adverse_list["data"]["items"];
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }else{
                        this.adverse_list_item = new Array();
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }//end if

                    this.global.loading = false;

                })
                .catch((error) => console.error(error));
        }
    }//


    
        
        
        prev(){
            const val           = this.form.value;
            if(this.pageNumber > 0){
                this.pageNumber -= 1;
                this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                this.load_adverse();
            }//end if
            
            
            
        }
    
        next(){
            const val           = this.form.value;
            const total_pages   = Math.ceil((this.total_search_result / val.page_size_placeholder));
            
           
            if(total_pages > (this.pageNumber + 1)){
                
                this.pageNumber             += 1;
               
                this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                this.load_adverse();
            }//end if
        }

        generate_report_adverse(){
            this.global.loading = true;
            const val = this.form.value;
            let full_name = this.data_list[0].FULL_NAME;
            const filter_adverse = this.form_filter_adverse.value;
            if(filter_adverse.filter_adverse_media_topic !== undefined || filter_adverse.filter_adverse_media_topic.trim() !== ''){
                full_name += " "+filter_adverse.filter_adverse_media_topic.toLocaleLowerCase();
            }//end if

            const file_name = full_name.toLocaleLowerCase()+".pdf";
            const search_id = this.snapshot_param["id"]+"-adverse";
            let request_param = {
                "search_query": full_name,
                "file_name": file_name,
                "generate_new" : true,
                "search_id" : search_id,
                "created_by" : this.global.login_session["id"],
                "search_type" : this.snapshot_param['search_type'],
                "sort" : val.sort_by,
                "url": window.location.origin+"/search-result/report-adverse-single/"+this.snapshot_param['search_type']+"/"+search_id

            };
        
            
            if(filter_adverse.filter_adverse_media_topic !== undefined && filter_adverse.filter_adverse_media_topic !== ""){
                request_param["adverse_topic"] = filter_adverse.filter_adverse_media_topic;
            }//end if

            console.log(request_param);
            this.manifestService.generate_manifest_adverse_single(request_param).subscribe(result =>{
                console.log(result);
                if(result["success"]){
                    window.open(result["data"]["manifest_link"],"_blank");
                }else{
                    console.log(result);
                }
                this.global.loading = false;
            });

        }//end



        generate_report_single(){
            this.global.loading = true;
            const full_name = this.data_list[0].FULL_NAME;
            const file_name = full_name.toLocaleLowerCase()+".pdf";
            const search_id = this.snapshot_param["id"];
            
            let request_param = {
                "created_by" : this.global.login_session["id"],
                "file_name": file_name,
                "search_id" : search_id,
                "generate_new" : true,
                "search_type" : this.snapshot_param['search_type'],
                "url": window.location.origin+"/search-result/report-single/"+this.snapshot_param['search_type']+"/"+search_id

            };



            console.log(request_param);
            this.manifestService.generate_manifest_single(request_param).subscribe(result =>{
                console.log(result);
                if(result["success"]){
                    window.open(result["data"]["manifest_link"],"_blank");
                }else{
                    console.log(result);
                }
                this.global.loading = false;
            });

        }//end


        selected_t(id){
            this.selected_tab = id;
        }//end if

        reload_adverse(){
            this.row_counter = 0;
            const val = this.form_filter_adverse.value;
            this.load_adverse(true);
        }


        load_adverse_parameter(){
            var request_param = {
                "from" : 0,
                "size" : "1000"
            };
            var index = environment.elastic_adverse_parameters;
            return this.searchService.getMasterDataElastic(index,request_param).pipe(
                shareReplay(1)
            );
        }
        
    }
