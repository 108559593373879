<div class="container-fluid">
    <div class="row mx-3">
        <div class="col">
            <div class="card py-2 shadow">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <h5 class="fw-bolder">Bulk Search</h5>
                        </div>
                        <!-- <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
                            <div class="d-grid gap-2">
                                <button type="button" (click)="submit()" [disabled]="isDisabled" class="btn btn-primary font-700" >Search {{search_type}}</button>
                            </div>
                        </div> -->
                        <!-- <div class="col col-auto">
                        </div> -->
                    </div>
                    <div class="row align-items-center gx-2">
                        <div class="col-auto">
                            <label class="form-label">
                                <small class="text-dashboard-muted">Please ensure your CSV file follows the <b>Bulk Search Template</b> to avoid errors during the upload process. <a href="assets/template/UCheck Bulk Search Template 1.csv" download="bulk_search_template">Download Bulk Search Template</a></small>
                            </label>
                        </div>
                    </div>
                    <hr/>
                    <form [formGroup]="form">
                        <div class="row align-items-center gx-2">
                            <label class="form-label">
                                <small class="text-dashboard-muted">List Reference</small>
                            </label>
                        </div>
                        
                        <div class="row align-items-center gx-2">
                            <div class="col-xxl-9 col-xl-9 col-lg-9 col-auto">
                                <input (change)="upload($event)" formControlName="file" accept=".docx, .doc, .xlsx, .xls, .csv" type="file" id="upload-id" class="custom-file-bulk {{form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['invalid_file']? 'custom-file-bulk-invalid' : ''}}" />
                            </div>
                            <div class="text-center  col-xl-3 col-lg-3 col-auto">
                                <button type="button" (click)="submit()" [disabled]="isDisabled" class="btn btn-primary font-700" >Search {{search_type}}</button>
                            </div>
                            
                        </div>
                        <!-- <div class="row align-items-center gx-2">
                            
                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
                                <button type="button" (click)="submit()" [disabled]="isDisabled" class="btn btn-primary font-700" >Search {{search_type}}</button>
                            </div>
                            
                        </div> -->
                        <div class="row align-items-center" *ngIf="totalFileSize > 0">
                            <div class="col-auto">
                                <small class="text-danger" *ngIf="form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['exceed_file_size']">
                                    {{field_label['file']}} max size exceeded!
                                </small>

                                <small class="text-danger" *ngIf="form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['invalid_file']">
                                    Invalid file type. Please upload a CSV file.
                                </small>
                            </div>
                        </div>
                        <div class="row align-items-center" *ngIf="totalFileSize > 0">
                            <div class="col-auto">
                                <small class="fw-bold">Total Size : {{ commonService.catchIsNaN(totalFileSize,2)}} MB</small>
                            </div>
                        </div>
                        
                    </form>

                    <form>
                        <div class="row mx-3 mb-3 mt-3 justify-content-end align-items-start">
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <!-- <a target="_blank" class="ms-3 btn btn-sm btn-primary text-white" data-bs-toggle="modal" data-bs-target="#confirmation_modal">Generate report</a> -->
                                    <input class="form-check-input" type="checkbox">
                                    <!-- data-bs-toggle="modal" data-bs-target="#confirmation_modal" -->
                                    <label class="form-check-label" for="">
                                        Include Consolidated PEP List
                                    </label>
                                </div>
                            </div>
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="">
                                        Include Adverse Media
                                    </label>
                                </div>
                            </div>
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <a tabindex="0" onmouseover="enablePopover()" role="button" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-content="Consolidating PEP List and Adverse Media are paid service. Additional charges may apply.">
                                        <div class="info-img"></div>
                                    </a>
                                </div>
                            </div>

                            <!-- <div class="col-auto">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="">
                                        Download:
                                        <a href="assets/template/UCheck Bulk Search Template 1.csv" download="bulk_search_template">Bulk Search Template</a>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</div>
           
<div class="container-fluid mt-5">
    <div class="row justify-content-center mx-3">
        <div class="col-12">
            
            <p class="font-14 fw-bold">Recent Upload(s)</p>
            <div class="shadow bg-white rounded">


                <ul class="nav nav-pills-custom mb-5" id="pills-tab" role="tablist">
                    <li class="nav-item me-2" role="presentation">
                        <button class="nav-link active fw-bold" id="sanction-tab" (click)="change_tab($event,'sanction-tab')" data-bs-toggle="pill" data-bs-target="#sanction" type="button" role="tab" aria-controls="sanction" aria-selected="true">Sanction</button>
                    </li>
                    <li class="nav-item me-2" role="presentation">
                        <button class="nav-link fw-bold" id="pep-tab" (click)="change_tab($event,'pep-tab')" data-bs-toggle="pill" data-bs-target="#pep" type="button" role="tab" aria-controls="pep" aria-selected="false">PEP</button>
                    </li>
                    <li class="nav-item me-2" role="presentation">
                        <button class="nav-link fw-bold" id="adverse-tab" (click)="change_tab($event,'adverse-tab')" data-bs-toggle="pill" data-bs-target="#adverse" type="button" role="tab" aria-controls="adverse" aria-selected="false">Adverse News</button>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">

                    <!--pep tab-->
                    <div class="tab-pane fade show active" id="sanction" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                        <div *ngIf="total_result == 0">
                            <div class="row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="total_result > 0">
                            <div class="table-responsive" style="max-height:500px;">
                                <table class="table align-middle" style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            <th class="text-muted text-left px-3">File Name</th>
                                            <th class="text-muted text-left px-3">Name of Uploader</th>
                                            <th class="text-muted text-center px-3">No. of Records</th>
                                            <th class="text-muted text-center px-3">No. of Hits</th>
                                            <th class="text-muted text-left px-3">Date Uploaded</th>
                                            <th class="text-center text-center px-3">CSV</th>
                                            <th class="text-center text-center px-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of table_list; let x = index">
                                            
                                            <td class="text-left px-3">{{data.file_name}}</td>
                                            <td class="text-left px-3">{{data.name}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_records"] === undefined? 0 : data.no_of_records),0)}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_hits"] === undefined? 0 : data.no_of_hits),0)}}</td>
                                            <td class="text-left px-3">{{data.date_created_str}}</td>
                                            <td class="text-center px-3">
                                                <a *ngIf="data['manifest_link'] !== undefined && data['manifest_link'] !== ''" href="{{data['manifest_link']}}" target="_blank" download>
                                                    <img class="img-fluid" style="width:10.11px;height:12.64px;" src="../../assets/images/download_icon.png"/>
                                                </a>
                                            </td>
                                            <td class="text-center px-3">
                                                <a class="btn btn-sm btn-primary" href="main/bulk-search-vw/sanction/{{data['id']}}">View</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                    
                                </table>
                            </div><!--./.table-responsive-->
                            
            
                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form" *ngIf="show_total_search_result">
            
                                        <div class="row mb-3 align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
            
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                            
                                            <li class="page-item" (click)="next()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div><!--./.row-->
                        </div><!--./.div-->
                        
                    </div><!--./tab-pane-->
                    <!--sanction tab-->


                    <!--pep tab-->
                    <div class="tab-pane fade" id="pep" role="tabpanel" aria-labelledby="pep" tabindex="0">
                        <div *ngIf="total_result2 == 0">
                            <div class="row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="total_result2 > 0">
                            <div class="table-responsive" style="max-height:500px;">
                                <table class="table align-middle" style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            <th class="text-muted text-left px-3">File Name</th>
                                            <th class="text-muted text-left px-3">Name of Uploader</th>
                                            <th class="text-muted text-center px-3">No. of Records</th>
                                            <th class="text-muted text-center px-3">No. of Hits</th>
                                            <th class="text-muted text-left px-3">Date Uploaded</th>
                                            <th class="text-center text-center px-3">CSV</th>
                                            <th class="text-center text-center px-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of table_list2; let x = index">
                                            
                                            <td class="text-left px-3">{{data.file_name}}</td>
                                            <td class="text-left px-3">{{data.name}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_records"] === undefined? 0 : data.no_of_records),0)}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_hits"] === undefined? 0 : data.no_of_hits),0)}}</td>
                                            <td class="text-left px-3">{{data.date_created_str}}</td>
                                            <td class="text-center px-3">
                                                <a *ngIf="data['manifest_link'] !== undefined && data['manifest_link'] !== ''" href="{{data['manifest_link']}}" target="_blank" download>
                                                    <img class="img-fluid" style="width:10.11px;height:12.64px;" src="../../assets/images/download_icon.png"/>
                                                </a>
                                            </td>
                                            <td class="text-center px-3">
                                                <a class="btn btn-sm btn-primary" href="main/bulk-search-vw/pep/{{data['id']}}">View</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                    
                                </table>
                            </div><!--./.table-responsive-->
                            
            
                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form">
            
                                        <div class="row mb-3 align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search2()" formControlName="page_size_placeholder2" class="form-select form-control-plaintext" aria-label=".form-select-sm example">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
            
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter2}} - {{pagination_counter2}} of {{total_search_result2}}
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev2()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                            
                                            <li class="page-item" (click)="next2()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div><!--./.row-->
                        </div><!--./.div-->
                    </div><!--./tab-pane-->
                    <!--pep tab-->

                    <!--adverse tab-->
                    <div class="tab-pane fade" id="adverse" role="tabpanel" aria-labelledby="adverse" tabindex="0">
                        <div *ngIf="total_result3 == 0">
                            <div class="row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="total_result3 > 0">
                            <div class="table-responsive" style="max-height:500px;">
                                <table class="table align-middle" style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            <th class="text-muted text-left px-3">File Name</th>
                                            <th class="text-muted text-left px-3">Name of Uploader</th>
                                            <th class="text-muted text-center px-3">No. of Records</th>
                                            <th class="text-muted text-center px-3">No. of Hits</th>
                                            <th class="text-muted text-left px-3">Date Uploaded</th>
                                            <th class="text-center text-center px-3">CSV</th>
                                            <th class="text-center text-center px-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of table_list3; let x = index">
                                            
                                            <td class="text-left px-3">{{data.file_name}}</td>
                                            <td class="text-left px-3">{{data.name}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_records"] === undefined? 0 : data.no_of_records),0)}}</td>
                                            <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_hits"] === undefined? 0 : data.no_of_hits),0)}}</td>
                                            <td class="text-left px-3">{{data.date_created_str}}</td>
                                            <td class="text-center px-3">
                                                <a *ngIf="data['manifest_link'] !== undefined && data['manifest_link'] !== ''" href="{{data['manifest_link']}}" target="_blank" download>
                                                    <img class="img-fluid" style="width:10.11px;height:12.64px;" src="../../assets/images/download_icon.png"/>
                                                </a>
                                            </td>
                                            <td class="text-center px-3">
                                                <a class="btn btn-sm btn-primary" href="main/bulk-search-vw/adverse/{{data['id']}}">View</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                    
                                </table>
                            </div><!--./.table-responsive-->
                            
            
                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form">
            
                                        <div class="row mb-3 align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search3()" formControlName="page_size_placeholder2" class="form-select form-control-plaintext" aria-label=".form-select-sm example">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
            
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter3}} - {{pagination_counter3}} of {{total_search_result3}}
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev3()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                            
                                            <li class="page-item" (click)="next3()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div><!--./.row-->
                        </div><!--./.div-->
                    </div><!--./tab-pane-->
                    <!--adverse tab-->


                </div><!--./tab-content-->
                

                
            </div>


            


            


            

        </div>
            
    </div>
</div>

           
           
        